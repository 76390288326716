import { Enrollment } from "../academics/Enrollment";
import { ILeadImage } from "../../../interfaces/admissions/ILeadImage";

export class StudentProfile {
    constructor() {
        this.avatar = undefined;
        this.studentName = undefined;
        this.studentNumber = undefined;
        this.studentId = undefined;
        this.leadId = undefined;
        this.currentStatus = undefined;
        this.currentProgramVersion = undefined;
        this.startDate = undefined;
        this.lastDateOfAttendance = undefined;
        this.campus = undefined;
        this.enrollments = undefined;
        this.firstName = undefined;
        this.lastName = undefined;
        this.afaStudentId = undefined;
        this.profilePhoto = undefined;
    }
    studentName: string | undefined;
    studentNumber: string | undefined;
    studentId: string | undefined;
    leadId: string | undefined;
    currentStatus: string | undefined;
    currentProgramVersion: string | undefined;
    startDate: string | undefined;
    lastDateOfAttendance: string | undefined;
    campus: string | undefined;
    avatar: string | undefined;
    enrollments: Array<Enrollment> | undefined;
    selectedEnrollment: Enrollment | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    ssn: string | undefined;
    afaStudentId: string | undefined;
    profilePhoto: ILeadImage | undefined;
    currentEnrollmentId?: string;
    campusId?: string;
}
