import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
  IStudentLedger,
  IEvaluatedPolicy,
  IEvaluatedPolicies,
  ITerminationDetail,
  ITerminationReportProps,
  IList,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import RefundPolicy from "./RefundPolicy";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
  })
);
interface ITerminationProps {
  student: StudentProfile;
  enrollment: IStudentEnrollments | undefined;
  enrollmentId: string;
  terminationId: string;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
}

const ApproveTerminationTab = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [campusId, setCampusId] = React.useState<string>(props.campusId);
  const [enrollmentId, setEnrollmentId] = React.useState<string>(props.enrollmentId);
  const [student, setStudent] = React.useState<StudentProfile>(props.student);
  const [terminationId, setTerminationId] = React.useState<string>(props.terminationId);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [titleIVGrants, setTitleIVGrants] = React.useState<IR2T4Step1Input>();
  const [terminationDetail, setTerminationDetail] = React.useState<ITerminationDetail>();
  //const [r2T4Input, setR2T4Input] = React.useState<IR2T4Input>();
  const [programVersionDetail, setProgramVersionDetail] = React.useState<IProgramVersionDetail>();
  const [scheduleHoursToComplete, setScheduleHoursToComplete] = React.useState<IScheduleHoursToComplete>();
  const [userAllowedToOverrideR2T4Input, setUserAllowedToOverrideR2T4Input] = React.useState<boolean>(false);
  const [override, setOverride] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setCampusId(props.campusId);
    setEnrollmentId(props.enrollmentId);
    setEnrollment(props.enrollment);
    setStudent(props.student);
    setTerminationId(props.terminationId);
    GetTerminationDetails(props.terminationId);
    GetUserAllowedToOverrideR2T4Input();
  }, [props]);

  const GetTerminationDetails = (terminationId: string) => {
    terminationApi.GetTerminationDetails(terminationId).then(
      (response: any) => {
        if (response) {
          //alert(JSON.stringify(response))
          setTerminationDetail(response);
        }
      },
      (exception: any) => {}
    );
  };

  const generateStudentSummaryReport = (reportLocation: string) => {
    var reportProps: ITerminationReportProps = {
      CampusId: campusId,
      StudentTerminationId: terminationId,
      isPreviewReport: false,
      reportCategory: "Termination",
      reportExtension: ".pdf",
      reportName: "Termination Details",
    };
    terminationApi.generateStudentSummaryReport(reportLocation, reportProps);
  };

  const GetUserAllowedToOverrideR2T4Input = () => {
    terminationApi.GetIsUserAllowedToOverrideR2T4InputOrResultsInfo().then(
      (response: any) => {
        setUserAllowedToOverrideR2T4Input(response);
      },
      (exception: any) => {}
    );
  };

  const onApprove = () => {
    props.setConfirmationDialog({
      open: true,
      message: "Are you sure you want to terminate the (" + student?.studentNumber + ") " + student?.studentName + " from " + student?.currentProgramVersion,
      onOk: () => {
        handleStudentTermination();
      },
      onCancel: () => setLoading(false),
    });
  };

  const handleStudentTermination = async () => {
    try {
      const reportPaths = [
        "StudentTermination/StudentTerminationSummary",
        "StudentTermination/R2T4ResultsClockHour",
        "StudentTermination/RefundCalculation",
      ];
  
      await Promise.all(
        reportPaths.map((reportPath) => generateStudentSummaryReport(reportPath))
      );
  
      const response = await terminationApi.ProcessRefund(terminationId);
  
      if (response && response.resultStatus === 0) {
        const successMessage = `(${student?.studentNumber}) ${student?.studentName} is terminated from ${student?.currentProgramVersion}`;
        props.setSnackBar(() => ({
          variant: "success",
          showSnackBar: true,
          messageInfo: successMessage,
        }));
      }
    } catch (error) {
      console.error("Error handling student termination:", error);
      props.setSnackBar(() => ({
        variant: "error",
        showSnackBar: true,
        messageInfo: "An error occurred during the student termination process.",
      }));
    }
  };  

   const onCancel = async (confirm?: boolean) => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to cancel the student termination process? All current data entered, including the previously saved data, will be lost",
      onOk: () => deleteTermination(),
      onCancel: () => setLoading(false),
    });
  };

  const deleteTermination = () => {
    if (terminationId != EmptyGuid) {
      props.deleteTermination(terminationId);
    }
  };


  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>SSN: </b>
              {student?.ssn}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>Enrollment: </b>
              {enrollment?.programVersionDescription}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Termination Detail</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Enrollment:</TableCell>
                        <TableCell>
                          {terminationDetail?.enrollmentName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell>{terminationDetail?.status}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Drop reason:</TableCell>
                        <TableCell>{terminationDetail?.dropReason}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Last date attended:</TableCell>
                        <TableCell>
                          {terminationDetail?.lastDateAttended}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Withdrawal date:</TableCell>
                        <TableCell>
                          {terminationDetail?.withdrawalDate}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Date of determination:</TableCell>
                        <TableCell>
                          {terminationDetail?.dateOfDetermination}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              generateStudentSummaryReport(
                                "StudentTermination/StudentTerminationSummary"
                              );
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            Preview
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>R2T4 Calculation Results</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Total charges:</TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalCharges
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Title IV aid:</TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalTitleIvAid
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Title IV aid disbursed:</TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalTitleIvAidDisbursed
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Percentage of Title IV aid earned:
                        </TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.percentageOfTitleIvAidEarned
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Title IV aid to return:</TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalTitleIvAidToReturn
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Amount to be returned by school: </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalAmountToBeReturnedBySchool
                            }
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Amount to be returned by student:</TableCell>
                        <TableCell>
                          {terminationDetail?.r2T4CalculationSummaryDetail?.amountToBeReturnedByStudent?.map(
                            (st: IList, index: any) => (
                              <TableCell key={index}>{st.text}</TableCell>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {
                              terminationDetail?.r2T4CalculationSummaryDetail
                                ?.totalAmountToBeReturnedByStudent
                            }
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              generateStudentSummaryReport(
                                "StudentTermination/R2T4ResultsClockHour"
                              );
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            Preview
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Refund calculation</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <RefundPolicy
                terminationId={terminationId}
                page="termination"
                pageKey={1}
              />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          generateStudentSummaryReport(
                            "StudentTermination/RefundCalculation"
                          );
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Preview
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>Additional information</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Box>
                1. The Tuition is not charged by payment period and ‘Payment
                Period’ is the Period used for calculation. As a result, the
                higher of Box L or Box E minus credit balance refunded to
                student was used for Box L under subsection N of ‘Step 5: Amount
                of unearned Title IV aid due from the school'. The following
                values were used in this comparison:
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Total Institutional charges (Box L):
                        </TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalCharges
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Credit balance refunded to student:
                        </TableCell>
                        <TableCell>
                          {
                            terminationDetail?.additionalInformationDetail
                              ?.creditBalanceRefunded
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Total Title IV aid disbursed for the period (Box E):
                        </TableCell>
                        <TableCell>
                          {
                            terminationDetail?.r2T4CalculationSummaryDetail
                              ?.totalTitleIvAid
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              generateStudentSummaryReport(
                                "StudentTermination/RefundCalculation"
                              );
                            }}
                            variant="contained"
                            color="secondary"
                          >
                            Preview
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid spacing={1}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.setTab(3)}
              style={{ marginRight: "8px" }}
            >
              &lt; Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.setTab(4)}
              style={{ marginRight: "8px" }}
            >
              Next &gt;
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onCancel(true)}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onApprove()}
              style={{
                marginRight: "8px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Approve Termination
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApproveTerminationTab;
