import API from "../../apiWrapper";
import { ICreditBalance } from '../../../interfaces/student/financials/ICreditBalance';

export const getStudentsCreditBalances = (
  campusId:string,
  year:number
):Promise<ICreditBalance[]> => {
  return API()
    .get(`/StudentAccount/CreditBalance/GetStudentsCreditBalance?campusId=${campusId}&year=${year}`)
    .then(
      (res: any) => {
        if (res.data) {
          return res.data;
        }
      },
      (error: any) => {
        return error;
      }
    );
};


export const upsertStudentsCreditBalances = (
    campusId:string,
    creditBalances:ICreditBalance[]
  ):Promise<boolean> => {
    return API()
      .post(`/StudentAccount/CreditBalance/UpsertAdjustedCreditBalance?campusId=${campusId}`,creditBalances)
      .then(
        (res: any) => {
          if (res.data) {
            return res.data;
          }
        },
        (error: any) => {
          return error;
        }
      );
  };
