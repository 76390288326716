import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  FormControlLabel,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import ReportOverview from "../../../components/_Layout/Reports/ReportOverview";
import { IReportOverview } from "../../../interfaces/reports/IReportOverview";
import { useSelector } from "react-redux";
import MultiCampusAutoComplete from "../../../components/AutoComplete/MultiCampusAutoComplete";
import { cloneDeep } from "lodash";
import { ISprGradRates2YrInstDetailParams } from "../../../interfaces/reports/ISprGradRates2YrInstDetailParams";
import { getSprGradRates2YrInstSection3DetailReport } from "../../../api/reports/ipeds/SprGradRates2YrInstSection3DetailReportApi";
import ProgramAutoComplete from "../../../components/AutoComplete/ProgramAutoComplete";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      minHeight: "100%",
      height: "100%",
      maxWidth: "1050px",
      padding: theme.spacing(2),
    },
    "& .makeStyles-content": {
      backgroundColor: theme.palette.background.paper,
    },
    paperRoot: {
      padding: theme.spacing(3, 2),
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    searchActions: {
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    startDateField: {
      width: "100%",
    },
    cardTitle: {
      fontSize: 17,
      color: theme.palette.primary.headerText,
      textDecoration: "bold",
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(1, 1),
      marginBottom: theme.spacing(1),
    },
    headerText: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    cardContent: {
      padding: theme.spacing(1, 3),
    },
    switch: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 0.5),
      height: theme.spacing(8.6),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ICohortYearDataSource {
  startDate: string;
  endDate: string;
  cohortYear: number;
  reportYearDescription: string;
}

const SprGradRates2YrInstSection3DetailReport = (props: any) => {
  const { report } = props;
  let reportOverview = { report: report } as IReportOverview;
  const classes = useStyles({});
  const [preview, setPreview] = React.useState<any>();
  const [previewLoader, setPreviewLoader] = React.useState<boolean>();
  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  const currentYear = new Date().getFullYear() - 4;
  const cohortYearDataSource = React.useMemo(() => {
    let toReturn: ICohortYearDataSource[] = [];
    let startYear: number = 2015;
    let endYear: number = new Date().getFullYear() + 1;

    for (let i = endYear; i > startYear; i--) {
      toReturn.push({
        endDate: `08/31/${i + 1}`,
        cohortYear: i,
        reportYearDescription: `${i - 1} thru ${i}`,
        startDate: `09/01/${i}`,
      });
    }

    return toReturn;
  }, []);

  const cohortYearAutoComplete = {
    options: cohortYearDataSource,
    getOptionLabel: (option: ICohortYearDataSource) =>
      option.cohortYear.toString(),
  };

  const cohortYearOnChange = (e: ICohortYearDataSource) => {
    if (!!e && !!e.cohortYear && e.cohortYear > 0) {
      let updatedModel = { ...model };
      updatedModel.startDate =
        model.cohortPossible == "full year"
          ? e.startDate
          : `${e.cohortYear}/10/15`;
      updatedModel.endDate =
        model.cohortPossible == "full year"
          ? e.endDate
          : `${e.cohortYear}/10/15`;
      updatedModel.cohortYear = e.cohortYear;
      updatedModel.selectedAcademicYearDate = new Date(e.cohortYear, 9, 15);
      setModel({ ...updatedModel });
    }
  };

  const data = useForm<any>({ mode: "onBlur" });
  const initialModel: ISprGradRates2YrInstDetailParams = {
    campusId: userSelectedCampus,
    startDate: cohortYearDataSource.find((m) => m.cohortYear === currentYear)
      ?.startDate,
    endDate: cohortYearDataSource.find((m) => m.cohortYear === currentYear)
      ?.endDate,
    progIdList: [],
    cohortYear: currentYear,
    orderBy: "Student Number",
    cohortPossible: "full year",
    showSSN: true,
    selectedAcademicYearDate: new Date(currentYear, 9, 15),
    showInactive: false,
  };
  const [model, setModel] =
    React.useState<ISprGradRates2YrInstDetailParams>(initialModel);

  const handleFieldOnChange = (
    fieldId: string,
    value: any,
    mapFunction?: Function
  ) => {
    let updatedModel = model;
    let newValue = mapFunction ? mapFunction(value) : value;
    (updatedModel as any)[fieldId] = newValue;
    if (fieldId == "campusId") {
      (updatedModel as any)["progIdList"] = [];
    }

    if (fieldId == "cohortPossible") {
      const selectedCohortYear = cohortYearDataSource.find(
        (m) => m.cohortYear == model.cohortYear
      );
      if (value == "full year") {
        if (
          !!selectedCohortYear &&
          !!model.cohortYear &&
          model.cohortYear > 1
        ) {
          updatedModel.startDate = selectedCohortYear.startDate;
          updatedModel.endDate = selectedCohortYear.endDate;
        } else {
          updatedModel.startDate = `${model.cohortYear ?? currentYear}/09/01`;
          updatedModel.endDate = `${model.cohortYear ?? currentYear}/08/31`;
        }
      } else {
        updatedModel.startDate = `${model.cohortYear ?? currentYear}/10/15`;
        updatedModel.endDate = `${model.cohortYear ?? currentYear}/10/15`;
        (updatedModel["selectedAcademicYearDate"] as any) = `${
          model.cohortYear ?? currentYear
        }/10/15`;
      }
    }

    if (fieldId == "selectedAcademicYearDate" && !!value) {
      updatedModel.startDate = value;
      updatedModel.endDate = value;
    }

    setModel({ ...updatedModel });
  };

  const { handleSubmit } = data;

  const onSubmit = async (isPreview: any) => {
    let params =
      (await getReportParameters()) as ISprGradRates2YrInstDetailParams;
    const validation = await data.triggerValidation();
    if (validation) {
      await getSprGradRates2YrInstSection3DetailReport(params, isPreview);
    }
  };

  const filterHandler = (data: any) => {
    if (data) {
      setModel(data);
    } else {
      setModel(initialModel);
    }
  };

  const handleSortByChange = (value: "LastName" | "Student Number") => {
    let copy = cloneDeep(model);
    copy.orderBy = value;
    setModel(copy);
  };

  const setPrograms = (programs: any) => {
    let newProgram: string[] = [];
    if (programs !== null) {
      if (Array.isArray(programs)) {
        newProgram = programs.map((gr: any) => gr.value);
      } else {
        newProgram.push(programs.value);
      }
    }
    return newProgram;
  };

  const getReportParameters = async () => {
    const success = await data.triggerValidation();
    if (model && success) {
      let startDate = model?.startDate ? model.startDate : null;
      let endDate = model?.endDate ? model.endDate : null;
      let params: ISprGradRates2YrInstDetailParams = {
        campusId: model?.campusId ?? userSelectedCampus,
        startDate: startDate,
        endDate: endDate,
        progIdList: model?.progIdList ?? [],
        cohortYear: model?.cohortYear,
        orderBy: model.orderBy,
        cohortPossible: model.cohortPossible,
        showSSN: model.showSSN,
        selectedAcademicYearDate: model.selectedAcademicYearDate,
      };
      return params;
    }
    return {} as ISprGradRates2YrInstDetailParams;
  };

  let parameters = (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              <MultiCampusAutoComplete
                label="Campus *"
                multiple={false}
                id="campusId"
                name="campusId"
                valueFilter={
                  model.campusId
                    ? {
                        key: "value",
                        values: [model.campusId],
                      }
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChange("campusId", v ? v.value : null);
                }}
                error={!!data.errors.campusId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.campusId ? "Campus is required." : undefined
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <ProgramAutoComplete
                chipSize="small"
                name="progIdList"
                multiple
                includeAllValue={true}
                showIncludeAllValue={true}
                valueFilter={
                  model.progIdList
                    ? {
                        key: "value",
                        values: model.progIdList,
                      }
                    : undefined
                }
                filterHandle={(v: any) =>
                  handleFieldOnChange("progIdList", v || [], setPrograms)
                }
                activeOnly={model.showInactive ? false : true}
                label="Program"
                campusIds={model.campusId ? [model.campusId] : []}
              />
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <FormControl component="fieldset" className={classes.fieldSet}>
                  <FormLabel component="legend">Cohort</FormLabel>
                  <RadioGroup
                    row
                    aria-label="cohortPossible"
                    name="cohortPossible"
                    value={model.cohortPossible}
                    onChange={(e: any) => {
                      handleFieldOnChange("cohortPossible", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      labelPlacement="end"
                      value="full year"
                      control={<Radio color="default" />}
                      label="Full Year (Program Reporter)"
                    />
                    <FormControlLabel
                      labelPlacement="end"
                      value="fall"
                      control={<Radio color="default" />}
                      label="Fall (Academic Reporter)"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {model.cohortPossible == "fall" && (
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    minDate={new Date(model.cohortYear, 0, 1)}
                    maxDate={new Date(model.cohortYear, 11, 31)}
                    variant="inline"
                    format="MM/dd/yyyy"
                    id="selectedAcademicYearDate"
                    name="selectedAcademicYearDate"
                    label="Report Date *"
                    value={
                      model.selectedAcademicYearDate
                        ? model.selectedAcademicYearDate
                        : null
                    }
                    onChange={(e: any, value: any) =>
                      handleFieldOnChange(
                        "selectedAcademicYearDate",
                        value ? value : undefined
                      )
                    }
                    KeyboardButtonProps={{
                      "aria-label": "report date",
                    }}
                    error={!!data.errors["selectedAcademicYearDate"]}
                    innerRef={(ref: any) => {
                      data.register("selectedAcademicYearDate", {
                        required: true,
                      });
                      data.setValue(
                        "selectedAcademicYearDate",
                        model.selectedAcademicYearDate
                      );
                    }}
                    helperText={
                      data.errors["selectedAcademicYearDate"]
                        ? (
                            data.errors[
                              "selectedAcademicYearDate"
                            ] as FieldError
                          ).message
                        : undefined
                    }
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}

            <Grid item xs={4}>
              {model.cohortPossible == "full year" && (
                <Typography>
                  {`Report Date: ${
                    cohortYearDataSource.findIndex(
                      (m) => m.cohortYear === model.cohortYear
                    ) >= 0
                      ? (() => {
                          const currentYearData = cohortYearDataSource.find(
                            (m) => m.cohortYear === model.cohortYear
                          );
                          if (currentYearData) {
                            const startDate = currentYearData.startDate;
                            const endDate = currentYearData.endDate;
                            return `${startDate} thru ${endDate}`;
                          }
                          return "";
                        })()
                      : ""
                  }`}
                </Typography>
              )}
              <Autocomplete
                {...cohortYearAutoComplete}
                autoComplete
                includeInputInList
                value={
                  !!model.cohortYear
                    ? cohortYearDataSource.find(
                        (m) => m.cohortYear == model.cohortYear
                      )
                    : null
                }
                onChange={(e: any, v: any) => {
                  cohortYearOnChange(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Cohort Year *`}
                    name="cohortYear"
                    error={!!data.errors.cohortYear}
                    inputRef={data.register({ required: true })}
                    helperText={
                      data.errors.exportType
                        ? "Cohort Year is required."
                        : undefined
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.fieldSet}>
                <FormLabel component="legend">Sort By</FormLabel>
                <RadioGroup
                  row
                  aria-label="orderBy"
                  name="orderBy"
                  value={model.orderBy}
                  onChange={(e: any) => {
                    handleSortByChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    labelPlacement="end"
                    value="Student Number"
                    control={<Radio color="default" />}
                    label="Student number"
                  />
                  <FormControlLabel
                    labelPlacement="end"
                    value="LastName"
                    control={<Radio color="default" />}
                    label="Last Name"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
  reportOverview.parameters = parameters;

  return (
    <ReportOverview
      reportOverview={reportOverview}
      filterHandler={filterHandler}
      getReportParameters={getReportParameters}
      exportHandler={onSubmit}
      preview={preview}
      previewLoader={previewLoader}
      hidePreview={true}
    />
  );
};
export default SprGradRates2YrInstSection3DetailReport;
