import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
}

const ResultsTab7 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [model, setModel] = React.useState<ITerminationResult>();
  const [override, setOverride] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setModel(props.model);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>
                7. Initial Amount of Unearned Title IV Aid Due from the Student
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  From the amount of Title IV aid to be returned (Box K)
                  subtract the amount for the school to return (Box O).
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>Box K</b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell align="center">
                        <b>Box O</b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <InputField
                          type={InputType.TEXT}
                          label=""
                          name="txt7k"
                          key="txt7k"
                          readOnly={override == true ? false : true}
                          defaultValue={model?.txt7k}
                          inputProps={{style: { textAlign: 'right' }}}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("txt7K", v.target.value);
                          }}
                          error={!!data.errors.txt7k}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.txt7k
                              ? (data.errors.txt7k as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <b>-</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.TEXT}
                          label=""
                          name="txt7o"
                          key="txt7o"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={override == true ? false : true}
                          defaultValue={model?.txt7o}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("txt7o", v.target.value);
                          }}
                          error={!!data.errors.txt7o}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.txt7o
                              ? (data.errors.txt7o as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <b>=</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="boxQResult"
                          key="boxQResult"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={override == true ? false : true}
                          defaultValue={model?.boxQResult}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("boxQResult", v.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                <div className={classes.leftLabelBox}>Q.</div>
                                <div className={classes.leftLabel}>$</div>
                              </React.Fragment>
                            ),
                          }}
                          error={!!data.errors.boxQResult}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.boxQResult
                              ? (data.errors.boxQResult as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div>
                  If Box Q is ≤ zero. <b>Stop</b> If greater than zero, go to
                  Step 8
                </div>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab7;
