import * as React from "react";
import {
  makeStyles,
  createStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddFutureCharges from "./AddFutureCharges";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    cardTitle: {
      fontSize: 17,
      color: theme.palette.black,
      fontWeight: "bold",
      backgroundColor: theme.palette.site.secondary,
      padding: theme.spacing(0),
    },
    headerText: {
      float: "left",
      padding: theme.spacing(1.5),
    },
    dialogCloseButton: {
      float: "right",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
  })
);

type AddNotesPopUpProps = {
  terminationId: string;
  open: boolean;
  handleClose: any;
  isLoading: boolean;
  setLoading: any;
  setSnackBar: any;
  setConfirmationDialog: any;
};

const FutureChargesPopup = (props: Readonly<AddNotesPopUpProps>) => {
    const classes = useStyles({});
    const beforeClose = () => {
      props.handleClose(false);
    }
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{ square: true }}
        fullWidth={true}
        maxWidth="lg"
        open={props.open ?? false}
        onClose={beforeClose}
        aria-labelledby="notes-add-dialog-title"
      >
        <DialogTitle
          className={classes.cardTitle}
          disableTypography
          id="notes-add-dialog-title"
        >
          <Typography className={classes.cardTitle}>
            <div className={classes.headerText}>Future Charges</div>
            <IconButton
              aria-label="close"
              onClick={beforeClose}
              className={classes.dialogCloseButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddFutureCharges
            terminationId={props.terminationId}
            setSnackBar={props.setSnackBar}
            setConfirmationDialog={props.setConfirmationDialog}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FutureChargesPopup;
