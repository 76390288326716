//import { IPEDSGradRates2YearDetailParam } from "../../../interfaces/reports/IPEDSGradRates2YearDetail";
import { IPEDSSprGradRates200LT4YrInstDetailParam } from "../../../interfaces/reports/IPEDSSprGradRates200LT4YrInstDetail";

import { ToBlob } from "../../../utils/base64Helper";
import { Download } from "../../../utils/file";
import API from "../../apiWrapper";

  export const getGradRates200LT4YrInstDetailReport = (
    params: IPEDSSprGradRates200LT4YrInstDetailParam,
    isPreview: boolean = false
  ): Promise<any> => {
    console.clear();
    console.log(params)
    return API()
    .post("/Reports/IPEDS/SprGradRates200LT4YrInstDetail", params)
    .then(
        (response:any) => {
          if (isPreview) {
            return response.data.base64Data;
          }
          const extension = "pdf";
          var fileName =
            "GetSprGradRates200LT4YrInstDetail" + "." + extension;
  
          Download(ToBlob(response.data.base64Data), fileName);
          return undefined;
        },
        (_) => {
          return null;
        }
      );
  };