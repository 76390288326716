import * as React from 'react';
import {
	makeStyles,
	createStyles,
	Grid,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    withStyles,
    InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormContext, useForm, FieldError } from 'react-hook-form';
import InputField from '../../components/_Layout/Inputs/InputField';
import { InputType } from '../../constants/uiConstants/inputConstants';
import { ICreditBalance } from '../../interfaces/student/financials/ICreditBalance';
import * as CreditBalanceApi from '../../api/student/financials/creditBalanceApi';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import YearAutoComplete from '../../components/AutoComplete/YearAutoComplete';


const useStyles = makeStyles((theme: any) =>
	createStyles({
		cardTitle: {
			fontSize: 17,
			color: theme.palette.black,
			fontWeight: 'bold',
			backgroundColor: theme.palette.site.secondary,
			padding: theme.spacing(0),
		},
		headerText: {
			float: 'left',
			padding: theme.spacing(1.5),
		},
		dialogCloseButton: {
			float: 'right',
		},
		textField: {
			marginRight: theme.spacing(1),
			width: '100%',
		},
		mainContent: {
			minHeight: 600,
		},
		enrollmentAutocomplete: {
			textAlign: 'center',
		},
		enrollmentSelector: {
			fontSize: theme.spacing(1.5),
		},
		hidden: {
			display: 'none',
		},
		width100: {
			width: '100%',
		},
		cancelBtn: {
			marginRight: 5,
		},
		searchBarContainer: {
			backgroundColor: theme.palette.site.secondary,
			borderRadius: 20,
			paddingLeft: '10px !important',
			fontSize: 13,
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.black,
			paddingTop: '2.5px !important',
			paddingBottom: '2.5px !important',
		},
        tableContainer: {
            height: '400px',
            width: "100%",
        },
        tableEmptyRow: {
            height: '350px',
            textAlign: 'center',
            fontSize: 20,
            opacity: 0.3,
        },
        table: {
            width: '100%',
        },
        preLoaderCell: {
            textAlign: 'center',
            padding: theme.spacing(1)
        },
	})
);

const StyledTableCell = withStyles((theme: any) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.site.secondary,
            fontSize: 17,
            color: theme.palette.black,
            fontWeight: 'bold',
            textAlign: 'center',
            boxShadow: '1px 1px 0px 0px rgba(0,0,0,0.2)',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
        body: {
            fontSize: 16,
            color: theme.palette.black,
            fontWeight: 500,
            cursor: 'pointer',
            textAlign: 'left',
            '&:first-child': {
                width: '42px',
                padding: theme.spacing(0, 1)
            }
        },
    }),
)(TableCell);

type IProps = {
    open?: boolean;
    handleClose: () => void;
    setSnackBar: any;
};

const AddCreditBalance = (
	props: Readonly<IProps>
) => {
	const { open, handleClose } = props;
	const classes = useStyles({});
	const data = useForm<any>({ mode: 'onBlur' });
	const { handleSubmit } = data;
	const useDecimalFormat = true;
    const [creditBalances, setCreditBalances] = React.useState<ICreditBalance[]>([]);
    const currentYear = (new Date()).getFullYear();
    const [selectedYear, setSelectedYear] = React.useState<number>(currentYear - 1);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const campusId = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);


    const UpdateCreditBalances = () => {
    	data.triggerValidation().then((validation: any) => {
    		if (validation) {
                const copy = cloneDeep(creditBalances);
                CreditBalanceApi.upsertStudentsCreditBalances(campusId, copy)
                .then(res => {
                    props.setSnackBar(() => {
                        return {
                            variant: 'success',
                            showSnackBar: true,
                            messageInfo: 'Saved successfully.',
                        };
                    });

                    handleClose();
                })
                .catch(e => {
                    props.setSnackBar(() => {
                        return {
                            variant: 'error',
                            showSnackBar: true,
                            messageInfo: 'An error occurred. Failed to save.',
                        };
                    });
                });
    		}

    	});
    };

    React.useEffect(() => {
    	if (!!selectedYear && selectedYear > 0 && !!campusId && props.open == true) {
            setIsLoading(true);
            CreditBalanceApi.getStudentsCreditBalances(campusId, selectedYear)
            .then(res => {
                const copy = cloneDeep(res);
                setCreditBalances(copy);
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            });
    	}
    	else {
    		setCreditBalances([]);
    	}
    }, [selectedYear, campusId, props.open]);

    const handleAdjustedBalanceOnChange = (
        recId:string,
    	value: any,
    ) => {
        let copy = cloneDeep(creditBalances);
        let toModify = copy.find(m => m.recId == recId);

        if(!!toModify && !!toModify.currentCreditBalance && toModify.currentCreditBalance > 0){
            if(Number(value) <= Number(toModify.currentCreditBalance)){
                toModify.adjustedCreditBalance = value;
            }

            setCreditBalances(copy);
        }
    };

    
    const beforeClose = () => {
    	handleClose();
    };

    return (
    	<React.Fragment>
    		<FormContext {...data}>
    			<form onSubmit={handleSubmit(UpdateCreditBalances)}>
    				<Dialog
    					PaperProps={{ square: true }}
    					fullWidth={true}
    					maxWidth="md"
    					open={open ?? false}
    					onClose={beforeClose}
    					aria-labelledby="notes-add-dialog-title"
    				>
    					<DialogTitle
    						className={classes.cardTitle}
    						disableTypography
    						id="notes-add-dialog-title"
    					>
    						<Typography className={classes.cardTitle}>
    							<div className={classes.headerText}>Adjust 1098T Credit Balance</div>
    							<IconButton
    								aria-label="close"
    								onClick={beforeClose}
    								className={classes.dialogCloseButton}
    							>
    								<CloseIcon fontSize="small" />
    							</IconButton>
    						</Typography>
    					</DialogTitle>
    					<DialogContent
    						className={classes.mainContent}
    					>
    						{open &&
                                    <Grid container direction="row" spacing={1}>
                                    <Grid item xs={4}>&nbsp;</Grid>
                                <Grid item xs={4} sm={4} md={4}>
									<YearAutoComplete
										disabled={false}
										label="Year"
										name='Year'
									
										valueFilter={
                                            !!selectedYear
                                    	? {
                                    		key: 'value',
                                    		values: [selectedYear]
                                    	}
                                    	: undefined
										}
										filterHandle={(v: any) => {
											setSelectedYear(v ? v.value : null);
										}}
										params={{
											//offset: 3,
											t1098: 'true'
										}}
									/>
								
								</Grid>
                                <Grid item xs={12}>
                                        <Typography variant='body1'>Enter a credit balance amount for the end of the reported year to be included into the 1098T Box-1</Typography>
                                </Grid>
                                    	
                                    	<Grid item md={12}>
                                        <TableContainer component={Paper} square className={classes.tableContainer}>
                                                    <Table stickyHeader size='small' className={classes.table} aria-label='customized table'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Student Number</TableCell>
                                                                <TableCell>Student Name</TableCell>
                                                                <TableCell>{`Current Credit Balance ${selectedYear}`}</TableCell>
                                                                <TableCell>Adjusted Credit  Balance to Include in 1098-T Box 1</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>


                                                            {isLoading ?
                                                                <TableRow>
                                                                    <StyledTableCell colSpan={4} className={classes.preLoaderCell}>
                                                                        <CircularProgress />
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                                : creditBalances?.map((row: ICreditBalance) => (
                                                                    <TableRow>
                                                                        <TableCell>{row.studentNumber}</TableCell>
                                                                        <TableCell>{`${row.lastName}, ${row.firstName}`}</TableCell>
                                                                        <TableCell>{`$${Number(row.currentCreditBalance ?? 0).toFixed(2)}`}</TableCell>
                                                                        {(selectedYear < (currentYear - 1)) && <TableCell>{`$${Number(row.adjustedCreditBalance ?? 0).toFixed(2)}`}</TableCell>}
                                                                        {(selectedYear >= (currentYear - 1)) && <TableCell>
                                                                            
                                                                            <InputField
                                    			                                type={InputType.NUMBER}
                                    			                                label=""
                                    			                                name= {`adjustedAmount${row.recId}`}
                                    			                                key={`adjustedAmountKey${row.recId}`}
                                    			                                decimal
                                                                                decimalPlaces={2}
                                    			                                defaultValue={(row.adjustedCreditBalance ?? 0)}
                                    			                                onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                                                                                    handleAdjustedBalanceOnChange(row.recId,v.target.value);
                                    			                                }}
                                    			                                error={!!data.errors[`adjustedAmount${row.recId}`]}
                                    			                                inputRef={data.register({
                                    				                            validate: {
                                    					                                    maxValue: (value) => {
                                                                                                if(Number(value) <= Number((row?.currentCreditBalance ?? 0))){
                                                                                                    return true;
                                                                                                } else {
                                                                                                    return `Can't be greater than Current Credit Balance`
                                                                                                }
                                                                                            }
                                    				                                },
                                    			                                })}
                                    			                                helperText={
                                    				                                data.errors[`adjustedAmount${row.recId}`]
                                    					                            ? (data.errors[`adjustedAmount${row.recId}`] as FieldError).message
                                    					                            : undefined
                                    			                                }
                                                                                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                                    		                                />
                                                                            
                                                                            </TableCell>}

                                                                    </TableRow>
                                                                ))}

                                                            {
                                                                creditBalances?.length == 0 && !isLoading ?
                                                                    <TableRow>
                                                                        <TableCell className={classes.tableEmptyRow} colSpan={4}>No Data</TableCell>
                                                                    </TableRow>
                                                                    : null
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                    	</Grid>
                                    	
                                    	<Grid item md={12} sm={12} xs={12} style={{marginTop:20}}>
                                        <Button
                                    			className={classes.cancelBtn}
                                    			onClick={beforeClose}
                                    			size='small'
                                    			color="secondary"
                                    			variant="contained"
                                    			type="button"
                                    		>
                                                Cancel
                                    		</Button>

                                    		<Button
                                    			size='small'
                                    			color='primary'
                                    			variant='contained'
                                    			type='button'
                                    			onClick={UpdateCreditBalances}
                                    		>Save</Button>

                                    	</Grid>

                                    </Grid>
    						}
    					</DialogContent>
    				</Dialog>
    			</form>
    		</FormContext>
    	</React.Fragment>
    );
};

export default AddCreditBalance;
