import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import { IFutureCharges } from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import PaymentPeriodAutoComplete from "../../../components/AutoComplete/PaymentPeriodAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import TransactionCodeAutoComplete from "../../../components/AutoComplete/TransactionCodeAutoComplete";
import PeriodTypeAutoComplete from "../../../components/AutoComplete/PeriodTypeAutoComplete";
import * as ledgerApi from "../../../api/student/studentAccounts/ledgerApi";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
  })
);

interface IPropsFutureCharges {
  terminationId: string;
  setSnackBar: (params: any) => void;
  setConfirmationDialog: any;
}

const AddFutureCharges = (props: IPropsFutureCharges) => {
  const classes = useStyles({});
  const [loaderState, setLoaderState] = React.useState<boolean>(false);

  const data = useForm<any>({ mode: "onBlur" });
  const { handleSubmit } = data;

  const [terminationId, setTerminationId] = React.useState<string>(
    props.terminationId
  );
  const [futureCharges, setFutureCharges] = React.useState<IFutureCharges[]>(
    []
  );

  const userSelectedCampus = useSelector((state: any) =>
    state.userstate.getSelectedCampus(state.session.user.userId)
  );

  var defaultModel = {
    futureChargeId: EmptyGuid,
    terminationId: "",
    transCodeId: "",
    transCode: "",
    amount: 0,
    transactionPeriodInfo: {
      transactionPeriodInfoId: EmptyGuid,
      periodTypeId: null,
      periodType: "",
      period: 0,
      begin: 0,
      end: 0,
    },
  };

  const [model, setModel] = React.useState<IFutureCharges>(defaultModel);

  useEffect(() => {
    if (props.terminationId) {
      setTerminationId(props.terminationId);
      GetFutureCharges(props.terminationId);
      setModel({ ...model, terminationId: props.terminationId });
    }
  }, [props]);

  const GetFutureCharges = (terminationId: string) => {
    terminationApi.GetFutureCharges(terminationId).then(
      (response: any) => {
        if (response && response.result) {
          setFutureCharges(response.result);
        }
      },
      (exception: any) => {}
    );
  };

  const onSubmit = () => {
    data.triggerValidation().then((validation: any) => {
      if (validation) {
        setLoaderState(true);
        if(model.futureChargeId == EmptyGuid)
        {
          terminationApi.PostFutureCharges(model).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                setModel({ ...defaultModel, terminationId: terminationId });
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: "Charges posted successfully.",
                  };
                });
              } else {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: "Error posting charges.",
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        }
        else
        {
          terminationApi.UpdateFutureCharges(model).then(
            (response: any) => {
              if (response.resultStatus === 0) {
                setLoaderState(false);
                setModel({ ...defaultModel, terminationId: terminationId });
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo: "Charges posted successfully.",
                  };
                });
              } else {
                setLoaderState(false);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "error",
                    showSnackBar: true,
                    messageInfo: "Error posting charges.",
                  };
                });
              }
            },
            (exception: any) => {
              setLoaderState(false);
              props.setSnackBar((snackBarProps: any) => {
                return {
                  variant: "error",
                  showSnackBar: true,
                  messageInfo: exception,
                };
              });
            }
          );
        }        
      }
    });
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const handleCancel = () => {
    setModel({ ...defaultModel });
  };
  
  const deleteFutureCharges = (id: string) => {
    props.setConfirmationDialog({open: true, onOk: () => deleteConfirm(id), message: "Are you sure you want to delete charges?" });
  };

  const deleteConfirm = (id: string) => {
    terminationApi.DeleteFutureCharges(id).then(
      (response: any) => {
        if (response && response.result != "") {
          props.setSnackBar(() => {
            return {
              variant: "success",
              showSnackBar: true,
              messageInfo: "Charges deleted successfully.",
            };
          });
          GetFutureCharges(terminationId);
        }
        else
        {
          props.setSnackBar(() => {
            return {
              variant: "error",
              showSnackBar: true,
              messageInfo: "Error",
            };
          });
        }
      },
      (exception: any) => {}
    );
  };

  const editFutureCharges = (id: string) => {
    var charges = futureCharges.find((charge) => charge.futureChargeId === id);
    if(charges)
    {
      setModel({...charges});
    }
  };

  const handleFieldOnChangeSub = (fieldId: string, value: any) => {
    let newModel = model;
    let updatedModel = model.transactionPeriodInfo;
    (updatedModel as any)[fieldId] = value;

    if (fieldId == "period" || fieldId == "periodTypeId") {
      (newModel as any)[fieldId] = value;
    }
    setModel({ ...newModel, transactionPeriodInfo: updatedModel });
  };

  return (
    <div className={classes.root}>
      <FormContext {...data}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={6} sm={6} xs={12}>
              <TransactionCodeAutoComplete
                id="transCodeId"
                name="transCodeId"
                label="Transaction Code *"
                campusId={userSelectedCampus}
                params={{
                  fullWidth: true,
                }}
                filterHandle={(v: any) => {
                  handleFieldOnChange("transCodeId", v ? v.value : undefined);
                }}
                valueFilter={
                  model.transCodeId
                    ? {
                        key: "value",
                        values: [model.transCodeId],
                      }
                    : undefined
                }
                error={!!data.errors.transCodeId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.transCodeId
                    ? "Transaction code is required."
                    : undefined
                }
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <PeriodTypeAutoComplete
                id="periodTypeId"
                name="periodTypeId"
                label="Period Type *"
                valueFilter={
                  model.transactionPeriodInfo?.periodTypeId != undefined
                    ? {
                        key: "value",
                        values: [model.transactionPeriodInfo?.periodTypeId],
                      }
                    : undefined
                }
                error={!!data.errors.periodTypeId}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.periodTypeId
                    ? "Period type is required."
                    : undefined
                }
                filterHandle={(v: any) => {
                  handleFieldOnChangeSub(
                    "periodTypeId",
                    v ? v.value : undefined
                  );
                  handleFieldOnChangeSub("period", undefined);
                }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <PaymentPeriodAutoComplete
                id="period"
                name="period"
                label="Period *"
                periodType={model.transactionPeriodInfo?.periodTypeId}
                valueFilter={
                  model.transactionPeriodInfo?.period
                    ? {
                        key: "value",
                        values: [model.transactionPeriodInfo?.period],
                      }
                    : undefined
                }
                error={!!data.errors.period}
                inputRef={data.register({ required: true })}
                helperText={
                  data.errors.period ? "Payment period is required." : undefined
                }
                filterHandle={(v: any) => {
                  let updatedModel = model.transactionPeriodInfo;
                  (updatedModel as any)["period"] = v ? v.value : undefined;
                  (updatedModel as any)["begin"] = v ? v.begin : undefined;
                  (updatedModel as any)["end"] = v ? v.end : undefined;
                }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputField
                type={InputType.CURRENCY}
                label="Amount *"
                name="amount"
                key="amount"
                decimal
                thousandSeparator
                defaultValue={model?.amount}
                onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                  handleFieldOnChange("amount", v.target.value);
                }}
                error={!!data.errors.amount}
                inputRef={data.register({
                  required: "Amount is required.",
                })}
                helperText={
                  data.errors.amount
                    ? (data.errors.amount as FieldError).message
                    : undefined
                }
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container direction="row" spacing={4}>
                <Grid
                  item
                  container
                  xs={1}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid
                  item
                  container
                  xs={1}
                  alignContent="flex-start"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Button
                    onClick={handleCancel}
                    size="small"
                    color="secondary"
                    variant="contained"
                    type="button"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Transaction Code</TableCell>
                      <TableCell>Period Type</TableCell>
                      <TableCell>Period</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {futureCharges?.map((row: IFutureCharges, index: any) => (
                      <TableRow>
                        <TableCell>{row.transCode}</TableCell>
                        <TableCell>
                          {row.transactionPeriodInfo?.periodType}
                        </TableCell>
                        <TableCell>
                          {row.transactionPeriodInfo?.begin} -{" "}
                          {row.transactionPeriodInfo?.end}
                        </TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit Charges" style={{ padding: 0 }}>
                            <IconButton
                              color="primary"
                              aria-label="Edit"
                              component="span"
                              onClick={(e: any) => {
                                editFutureCharges(row.futureChargeId);
                              }}
                            >
                              <EditIcon></EditIcon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title="Delete Charges"
                            style={{ padding: 0 }}
                          >
                            <IconButton
                              onClick={() => {
                                deleteFutureCharges(row.futureChargeId);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </form>
      </FormContext>
    </div>
  );
};

export default AddFutureCharges;
