import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { DropReasonAPIAutoComplete } from "../../constants/SystemCatalog/DropReasonAPIAutoComplete";

export interface DropReasonAutoCompleteInput
  extends FilterParams {
    statusCodeId?: string;
}

const DropReasonAutoComplete = (props: DropReasonAutoCompleteInput) => {
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );

    const inputRef: any = React.useRef(null);
    const filterHandle = props.filterHandle;

    const OnChange = (e: any) => {
        if (filterHandle) {
            filterHandle(e);
        }
    };

    return (
        <div>
            <ApiAutoComplete
                config={DropReasonAPIAutoComplete.config}
                loading={userSelectedCampus ? false : true}
                reqParams={{ campusId: userSelectedCampus, statusCodeId: props.statusCodeId ? props.statusCodeId : null }}
                classes={{
                    option: "text-black"
                }}
                label={props.label ?? "Drop Reason"}
                onChange={(e: any) => {
                    OnChange(e);
                }}
                multiple={props.multiple || false}
                valueFilter={props.valueFilter ? props.valueFilter : undefined}
                inputRef={props.inputRef ? props.inputRef : inputRef}
                error={props.error}
                id={props.id}
                inputName={props.name}
                helperText={props.helperText}
                showIncludeAllValue={props.showIncludeAllValue}
                chipSize={props.chipSize}
                maxTags={props.maxTags}
                includeAllValue={props.includeAllValue ? props.includeAllValue : false}
            ></ApiAutoComplete>
        </div>
    );
};
export default DropReasonAutoComplete;
