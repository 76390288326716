import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Tab,
  Tabs,
  Box,
  Divider,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomSnackBarProps } from "../../../interfaces/common/CustomSnackBarProps";
import CustomSnackbar from "../../../components/notifications/CustomSnackbar";

import TerminationDetailTab from "./TerminationDetailTab";
import R2T4InputTab from "./R2T4InputTab";
import R2T4ResultsTab from "./R2T4ResultsTab";
import InstitutionalRefundTab from "./InstitutionalRefundTab";
import ApproveTerminationTab from "./ApproveTerminationTab";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import { EmptyGuid } from "../../../utils/constants";
import {
  IStudentEnrollments,
  IPeriodTypes,
} from "../../../interfaces/student/termination/IStudentTermination";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      minHeight: "100%",
      height: "100%",
      width: "100%",
      margin: "auto",
    },
    paperRoot: {
      width: "100%",
      minHeight: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      flexDirection: "column",
    },
    inner: {
      height: "100%",
      width: "100%",
      margin: "0 auto",
      minHeight: "100%",
      maxWidth: 1150,
    },
    fixed: {
      position: "sticky",
      top: "0",
      width: "100%",
      marginBottom: "0.5em",
    },
    divider: {
      backgroundColor: theme.palette.site.primary,
      marginBottom: theme.spacing(1),
    },
    tabController: {
      backgroundColor: "transparent",
      textTransform: "uppercase",
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.contrastNavTabColor,
    },
    tabSelected: { fontWeight: "bold" },
    right: {
      float: "right",
    },
    courseSelectionCell: {
      color: theme.palette.paperSummarySchedulerTitle,
      cursor: "pointer",
      fontWeight: 500,
    },
    courseSelectedCell: {
      backgroundColor: theme.palette.site.secondary,
      cursor: "pointer",
      fontWeight: 500,
    },
    sortIcon: {
      fontSize: 15,
      marginLeft: 10,
    },
    hiddenElement: {
      visibility: "hidden",
    },
    classLabel: {
      fontWeight: "bold",
    },
    sortDesc: {
      transform: "rotate(180deg)",
    },
  })
);

function Termination1() {
  const classes = useStyles();
  const [student, setStudent] = React.useState<StudentProfile>(
    new StudentProfile()
  );
  const [enrollments, setEnrollments] = React.useState<IStudentEnrollments[]>(
    []
  );
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [enrollmentId, setEnrollmentId] = React.useState<string>("");
  const [terminationId, setTerminationId] = React.useState<string>("");
  const [isResultExists, setIsResultExists] = React.useState<boolean>(false);
  const [isR2t4InputExists, setIsR2t4InputExists] =
    React.useState<boolean>(false);
  const [campusId, setCampusId] = React.useState<string>("");
  const [periodTypes, setPeriodTypes] = React.useState<IPeriodTypes[]>([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (_index: any, newValue: any) => {
    setValue(newValue);
  };

  const selectedStudent: StudentProfile = useSelector(
    (state: any) => state.student
  ) as StudentProfile;

  React.useEffect(() => {
    if (selectedStudent?.studentNumber != "") {
      getCalculationPeriodType();
      setEnrollmentId(selectedStudent?.selectedEnrollment?.stuEnrollId ?? "");
      setCampusId(selectedStudent?.selectedEnrollment?.campusId ?? "");
      setStudent(selectedStudent);
      getStudentEnrollments(selectedStudent.studentId);
      setValue(0);
    }
  }, [selectedStudent]);

  React.useEffect(() => {
    if (terminationId != "") {
      terminationApi.GetIsR2T4InputExists(terminationId).then(
        (response: any) => {
          setIsR2t4InputExists(response);
        },
        (exception: any) => {}
      );
      terminationApi.GetIsResultsExists(terminationId).then(
        (response: any) => {
          if (response) {
            setIsResultExists(response);
          }
        },
        (exception: any) => {}
      );
    }
  }, [terminationId]);

  React.useEffect(() => {
    if (enrollmentId != "") {
      getEnrollment(student?.studentId ?? "", enrollmentId);
    }
  }, [enrollmentId]);

  const getStudentEnrollments = (studentId: any) => {
    terminationApi.GetEnrollmentsByStudentId(studentId, true, false).then(
      (response: any) => {
        if (response) {         
          setEnrollments(response);
        }
      },
      (exception: any) => {}
    );
  };

  const getCalculationPeriodType = () => {
    terminationApi.GetCalculationPeriodType().then(
      (response: any) => {
        if (response) {
          setPeriodTypes(response);
        }
      },
      (exception: any) => {}
    );
  };

  const getEnrollment = (studentId: string, enrollmentId: string) => {
    terminationApi.GetEnrollmentDetail(studentId, enrollmentId).then(
      (response: any) => {
        if (response) {          
          setEnrollment(response);
        }
      },
      (exception: any) => {}
    );
  };

  const deleteTermination = (terminationId: string) => {
    if (terminationId != EmptyGuid)
      terminationApi.deleteTerminationById(terminationId).then(
        (response: any) => {
          if (response) {
            setTerminationId("");
            setSnackBarProps((props: any) => {
              return {
                variant: "success",
                showSnackBar: true,
                messageInfo: "Termination deleted successfully.",
              };
            });
            setValue(0);
            window.location.reload();
          }
        },
        (exception: any) => {}
      );
  };

  const [confirmationDialogue, setConfirmationDialogue] = React.useState<{
    onOk?: () => void;
    open: boolean;
    message?: string;
    onCancel?: () => void;
  }>({ open: false, message: "Do you want to save changes?" });

  const [snackBarProps, setSnackBarProps] = React.useState<CustomSnackBarProps>(
    {
      showSnackBar: false,
      messageInfo: undefined,
      variant: "info",
    }
  );

  return (
    <div className={classes.root}>
      <div className={classes.paperRoot}>
        <CustomSnackbar
          variant={snackBarProps.variant}
          message={snackBarProps.messageInfo}
          open={snackBarProps.showSnackBar}
          onClose={() => {
            setSnackBarProps((props: any) => {
              return { ...props, showSnackBar: false };
            });
          }}
        ></CustomSnackbar>
        <Card>
          <CardContent>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={10} md={10}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  scrollButtons="auto"
                  variant="fullWidth"
                  classes={{
                    indicator: classes.tabIndicator,
                  }}
                  className={classes.tabController}
                >
                  <Tab
                    classes={{ selected: classes.tabSelected }}
                    label={"Termination Detail"}
                    {...a11yProps(0)}
                  />
                  <Tab
                    classes={{ selected: classes.tabSelected }}
                    label={"R2T4 Input"}
                    disabled={
                      terminationId != "" && isR2t4InputExists == true
                        ? false
                        : true
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    classes={{ selected: classes.tabSelected }}
                    label={"R2T4 Results"}
                    disabled={
                      terminationId != "" && isResultExists == true
                        ? false
                        : true
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    classes={{ selected: classes.tabSelected }}
                    label={"Institutional Refund"}
                    disabled={
                      terminationId != "" && isResultExists == true
                        ? false
                        : true
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    classes={{ selected: classes.tabSelected }}
                    label={"Approve Termination"}
                    disabled={
                      terminationId != "" && isResultExists == true
                        ? false
                        : true
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
                <Divider className={classes.divider} />
                <TabPanel value={value} index={0}>
                  <TerminationDetailTab
                    student={student}
                    enrollment={enrollment}
                    enrollments={enrollments}
                    enrollmentId={enrollmentId}
                    periodTypes={periodTypes}
                    setEnrollmentId={setEnrollmentId}
                    setTerminationId={setTerminationId}
                    campusId={campusId}
                    setConfirmationDialog={setConfirmationDialogue}
                    setSnackBar={setSnackBarProps}
                    setTab={setValue}
                    deleteTermination={deleteTermination}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <R2T4InputTab
                    setConfirmationDialog={setConfirmationDialogue}
                    setSnackBar={setSnackBarProps}
                    setIsR2t4InputExists={setIsR2t4InputExists}
                    student={student}
                    enrollment={enrollment}
                    enrollmentId={enrollmentId}
                    terminationId={terminationId}
                    campusId={campusId}
                    setTab={setValue}
                    deleteTermination={deleteTermination}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <R2T4ResultsTab
                    setConfirmationDialog={setConfirmationDialogue}
                    setSnackBar={setSnackBarProps}
                    student={student}
                    enrollment={enrollment}
                    periodTypes={periodTypes}
                    enrollmentId={enrollmentId}
                    terminationId={terminationId}
                    isResultExists={isResultExists}
                    campusId={campusId}
                    setTab={setValue}
                    deleteTermination={deleteTermination}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <InstitutionalRefundTab
                    setConfirmationDialog={setConfirmationDialogue}
                    setSnackBar={setSnackBarProps}
                    student={student}
                    enrollment={enrollment}
                    enrollmentId={enrollmentId}
                    terminationId={terminationId}
                    campusId={campusId}
                    setTab={setValue}
                    deleteTermination={deleteTermination}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <ApproveTerminationTab
                    setConfirmationDialog={setConfirmationDialogue}
                    setSnackBar={setSnackBarProps}
                    student={student}
                    enrollment={enrollment}
                    enrollmentId={enrollmentId}
                    terminationId={terminationId}
                    campusId={campusId}
                    setTab={setValue}
                    deleteTermination={deleteTermination}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog"
          open={confirmationDialogue.open}
          onClose={() => {
            setConfirmationDialogue({ open: false });
            confirmationDialogue.onCancel && confirmationDialogue.onCancel();
          }}
        >
          <DialogContent dividers>
            <Typography>{confirmationDialogue.message}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                confirmationDialogue.onOk && confirmationDialogue.onOk();
                setConfirmationDialogue({ open: false });
              }}
              color="primary"
            >
              Confirm
            </Button>
            <Button
              autoFocus
              onClick={() => {
                setConfirmationDialogue({ open: false });
                confirmationDialogue.onCancel &&
                  confirmationDialogue.onCancel();
              }}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default Termination1;
