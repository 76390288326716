import * as React from "react";
import { useRef } from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ApiAutoComplete from "../../components/_Layout/ApiAutoComplete/ApiAutoComplete";
import { useSelector, useDispatch } from "react-redux";
import { FilterParams } from "../../interfaces/common/FilterParams";
import { ApiAutoCompleteConfigItem } from "../../interfaces/ApiAutoCompleteConfigItem";

const config: ApiAutoCompleteConfigItem = {
  acId: "terminationEnrollmentStatus",
  cacheTime: 5,
  route: "/AcademicRecords/StudentTermination/GetStudentTerminationStatusCodes",
  requestType: "GET",
  body: null,
  hasUrlParams: true,
  useCache: false,
  storeInCache: false,
  isCampusSpecific: true,
  isUserSpecific: false,
  loadOnRender: true,
  defaultSelectedFirstItem: false,
  optionsMappedFx: (data: Array<any>) => {
    return data;
  },
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {},
    divWidth: {
      width: "95%",
    },
    fullWidth: { width: "100%" },
  })
);

export interface TerminationEnrollmentStatusAutoCompleteInput
  extends FilterParams {
  studentEnrollmentId?: string;
  campusId?: string;
}

const TerminationEnrollmentStatusAutoComplete = (
  props: TerminationEnrollmentStatusAutoCompleteInput
) => {
  const classes = useStyles({});
  const selectedEnrollment = useSelector(
    (state: any) => state.student.selectedEnrollment
  );
  const inputRef: any = useRef(null);

  const { error, helperText } = props;

  const statusOnChange = (e: any) => {
    if (props.filterHandle) {
      props.filterHandle(e);
    }
  };

  return (
    <div
      className={
        props.params?.fullWidth
          ? clsx(classes.fullWidth)
          : clsx(classes.divWidth)
      }
    >
      <ApiAutoComplete
        config={config}
        loading={
          selectedEnrollment && selectedEnrollment.campusId ? false : true
        }
        reqParams={{
          campusId: props.campusId
            ? props.campusId
            : selectedEnrollment
            ? selectedEnrollment.campusId
            : "",
          studentEnrollmentId: props.studentEnrollmentId
            ? props.studentEnrollmentId
            : selectedEnrollment
            ? selectedEnrollment.studentEnrollmentId
            : "",
        }}
        includeAllValue={props.includeAllValue ? props.includeAllValue : false}
        defaultOptionText={
          props.defaultOptionText ? props.defaultOptionText : undefined
        }
        classes={{
          option: "text-black",
        }}
        label={props.label ? props.label : "Status"}
        placeholder={
          props.valueFilter &&
          props.valueFilter.values &&
          props.valueFilter.values.length > 0
            ? ""
            : "Select a status"
        }
        onChange={(e: any) => {
          statusOnChange(e);
        }}
        disabled={props.disabled}
        multiple={props.multiple ? props.multiple : false}
        valueFilter={props.valueFilter ? props.valueFilter : undefined}
        error={error}
        helperText={helperText}
        inputRef={props.inputRef ? props.inputRef : inputRef}
        inputName={props.name}
        showIncludeAllValue={props.showIncludeAllValue}
        chipSize={props.chipSize}
        maxTags={props.maxTags}
      ></ApiAutoComplete>
    </div>
  );
};
export default TerminationEnrollmentStatusAutoComplete;
