/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
	AppBar,
	Badge,
	Button,
	IconButton,
	Toolbar,
	Hidden,
	colors,
	Grid,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";

import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationsPopover from "../../notifications/NotificationsPopover/NotificationsPopover";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import CampusSearch from "../CampusSearch/CampusSearch";
import SearchBar from "../Search/SearchBar";
import { sessionActions as actions } from "../../../actions/sessionActions";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import {
	notificationConstants,
	userConstants,
	cookiesConstants,
} from "../../../constants/actions.constants";

import * as notificationsApi from "../../../api/Notifications/notificationsApi";
import * as actionTypes from '../../../constants/actions.constants';

import SchoolLogo from "../../security/SchoolLogo";
import themeTenantPicker from "../../../components/Common/ThemeTenantPicker";
import FireplaceIcon from '@material-ui/icons/Fireplace';
import { clearCache } from "../../../api/system/clearCacheApi";
import { EmptyGuid } from "../../../utils/constants";
import ConfirmationModal from "../Modal/ConfirmationModal";

interface ITopBarProps {
	className: string;
	onOpenNavBarMobile: any;
	logout?: () => void;
}

const TopBar = (props: ITopBarProps) => {
	const { onOpenNavBarMobile, className, logout, ...rest } = props;
	const themeName = themeTenantPicker();
	const useStyles = makeStyles((theme: any) =>
		createStyles({
			root: {
				paddingLeft: "0px",
				backgroundColor: theme.palette.topBar.background,
				padding: 0,
				zIndex: 10,
				boxShadow: theme.palette.boxShadow,
			},
			toolBar: {
				padding: 0,
			},
			searchInput: {
				flexGrow: 1,
				color: "inherit",
				"& input::placeholder": {
					opacity: 1,
					color: "inherit",
				},
			},
			searchPopper: {
				zIndex: theme.zIndex.appBar + 100,
			},
			searchPopperContent: {
				marginTop: theme.spacing(1),
			},
			trialButton: {
				marginLeft: theme.spacing(2),
				color: theme.palette.white,
				backgroundColor: colors.green[600],
				"&:hover": {
					backgroundColor: colors.green[900],
				},
			},
			helpButton: { color: theme.palette.topBar.icons },
			helpIcon: {
				fontSize: 24,
				color: theme.palette.topBar.icons,
			},
			helpBadge: { color: theme.palette.topBar.icons },
			logoutButton: { color: theme.palette.topBar.icons },
			logoutIcon: {
				marginLeft: theme.spacing(1),
				marginRight: theme.spacing(1),
				fontSize: 24,
				color: theme.palette.topBar.icons,
			},
			titleContainer: {
				minWidth: 240,
				maxWidth: 240,
			},
			campusSearchContainer: {
				width: "100%",
				marginTop: 10,
				minWidth: 300,
				maxWidth: 415,
			},
			actionButtonsContainer: {
				width: 270,
			},
			actionButtonsContainerTabletMode: {
				width: 90,
			},
			searchBarContainer: {
				width: "100%",
				marginTop: 11,
			},
			schoolLogo: {
				marginTop: 1,
			},
			notificationsButton: {
				marginLeft: theme.spacing(1),
				color: theme.palette.topBar.icons,
			},
			notificationsBadge: {
				backgroundColor: colors.orange[600],
			},
		})
	);

	const classes = useStyles({});
	const helpRef = useRef(null);
	const notificationsRef = useRef(null);
	const [tabletModeMenuActive, setTabletModeMenuActive] = useState(false);
	const isTabletMode = useMediaQuery("(max-width:1024px)");
	const dispatch = useDispatch();
	const userSelectedCampus = useSelector((state: any) =>
		state.userstate.getSelectedCampus(state.session.user.userId)
	);
	let notifications =
		useSelector((state: any) => state.session.user.notifications) || [];
	let persistentNotifications =
		useSelector((state: any) => state.session.user.persistentNotifications) ||
		[];
	const newNotification = useSelector(
		(state: any) => state.notification.newNotification
	);

	const [confirmationPopupState, setConfirmationPopupState] =
		React.useState<any>({
			id: "simple-popup",
			message: "",
		});

	const handleConfirmRemoveCache = async () => {
		localStorage.clear();
		sessionStorage.clear();

		dispatch({
			type: actionTypes.toasterConstants.SHOW_TOASTER,
			data: {
				message:
					"Cache Cleared Successfully.",
				showToaster: true,
				variant: "success"
			},
		});

		setTimeout(() => {
			dispatch({
				type: actionTypes.toasterConstants.HIDE_TOASTER,
			})
		}, 2000);

		window.location.href = "/login";
		setConfirmationPopupState({ open: false });
	};

	const handleRemoveCache = () => {
		setConfirmationPopupState((state: any) => {
			return {
				...state,
				open: true,
				confirmationText: "This will clear your browser cache and log you out. Do you wish to continue?",
				confirmationButton: "Yes",
				title: "Clear Cache",
				onConfirmEvent: () => {
					handleConfirmRemoveCache();
				},
				onCancelEvent: () => {
					setConfirmationPopupState((updatedState: any) => {
						return { ...updatedState, open: false };
					});
				},
			};
		});
	};


	const getPersistenNotifications = () => {
		notificationsApi
			.GetPersistentNotications(userSelectedCampus)
			.then((response: any) => {
				if (response && response.data && response.data.result) {
					dispatch({
						type: userConstants.SESSION_PERSISTENT_NOTIFICATION_RECEIVED,
						notifications: response.data.result.result,
					});
				}
			});
	};

	useEffect(() => {
		getPersistenNotifications();
	}, []);
	useEffect(() => {
		getPersistenNotifications();
	}, [userSelectedCampus]);
	const [openNotifications, setOpenNotifications] = useState(false);

	useEffect(() => { }, [isTabletMode]);

	const handleLogout = () => {
		const loggedInUser = localStorage.getItem(
			cookiesConstants.FAME_THEME_MODE_KEY
		);
		if (loggedInUser === cookiesConstants.FAME_THEME_MODE) {
			localStorage.removeItem(cookiesConstants.FAME_THEME_MODE_KEY);
			// localStorage.setItem(cookiesConstants.THEME_TENANT_KEY, cookiesConstants.DEFAULT_THEME);
			localStorage.removeItem("TrustedDomain");
			localStorage.removeItem("HostUrl");
			localStorage.removeItem("SiteUrl");
			localStorage.removeItem("ApiUrl");
		}
		props.logout && props.logout();
	};

	const tabletMenuButtonClick = () => {
		setTabletModeMenuActive(!tabletModeMenuActive);
	};

	const handleNotificationsOpen = () => {
		dispatch({ type: notificationConstants.NOTIFICATION_VIEWED });
		setOpenNotifications(true);
	};

	const handleNotificationsClose = () => {
		setOpenNotifications(false);
	};

	const handleHelpOpen = () => { };

	const handleHelpClose = () => { };

	const StyledMenu = withStyles({
		paper: {
			border: "1px solid #d3d4d5",
		},
	})((props: MenuProps) => (
		<Menu
			elevation={0}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			{...props}
		/>
	));

	const StyledMenuItem = withStyles((theme) => ({
		root: {},
	}))(MenuItem);

	const TabletSandwichMenu = () => {
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<div>
				<Button
					aria-controls="customized-menu"
					aria-haspopup="true"
					variant="contained"
					color="primary"
					onClick={handleClick}
				>
					<MenuIcon
						className={classes.logoutIcon}
					// onClick={tabletMenuButtonClick}
					/>
				</Button>
				<StyledMenu
					id="customized-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<StyledMenuItem>
						<ListItemIcon>
							<HelpOutlineRoundedIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Help" />
					</StyledMenuItem>
					<StyledMenuItem onClick={handleLogout}>
						<ListItemIcon>
							<InputIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</StyledMenuItem>
				</StyledMenu>
			</div>
		);
	};

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			color="primary"
			elevation={3}
		>
			<Toolbar className={clsx(classes.toolBar)}>
				<Grid container direction="row">
					<Grid item xs={2} className={classes.titleContainer}>
						<Grid
							container
							direction="column"
							justify="center"
							alignItems="center"
						>
							<Grid item xs={12}>
								<Link to="/dashboard">
									<SchoolLogo
										schoolName={themeName}
										logoType="Inverted"
									></SchoolLogo>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3}>
						<Grid
							container
							direction="column"
							justify="flex-start"
							alignItems="flex-start"
						>
							<Grid item xs={12} className={classes.campusSearchContainer}>
								<CampusSearch></CampusSearch>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs>
						<Grid
							container
							direction="column"
							justify="flex-end"
							alignItems="flex-end"
						>
							<Grid item xs={10} className={classes.searchBarContainer}>
								<Hidden smDown>
									<SearchBar></SearchBar>
								</Hidden>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					className={clsx(
						{ [classes.actionButtonsContainer]: !isTabletMode },
						{ [classes.actionButtonsContainerTabletMode]: isTabletMode }
					)}
				>
					{!isTabletMode && (
						<React.Fragment>
							<Grid
								container
								direction="column"
								justify="center"
								alignItems="center"
							>
								<Grid item xs={12}>
									<Tooltip title="Clear Cache">
										<IconButton
											className={classes.notificationsButton}
											color="inherit"
											onClick={handleRemoveCache}
											ref={notificationsRef}
										>
											<FireplaceIcon />
										</IconButton>
									</Tooltip>



									<IconButton
										className={classes.notificationsButton}
										color="inherit"
										onClick={handleNotificationsOpen}
										ref={notificationsRef}
									>
										<Badge
											invisible={
												!newNotification && persistentNotifications.length === 0
											}
											badgeContent={
												notifications.length + persistentNotifications.length
											}
											classes={{ badge: classes.notificationsBadge }}
											variant="dot"
										>
											<NotificationsIcon />
										</Badge>
									</IconButton>
									<IconButton
										className={classes.helpButton}
										color="inherit"
										onClick={handleHelpOpen}
										ref={helpRef}
									>
										<Badge
											classes={{
												badge: classes.helpBadge,
											}}
											variant="dot"
										>
											<HelpOutlineRoundedIcon className={classes.helpIcon} />
										</Badge>
									</IconButton>
									<Button
										className={classes.logoutButton}
										color="inherit"
										onClick={handleLogout}
									>
										<InputIcon
											className={classes.logoutIcon}
											onClick={handleLogout}
										/>
									</Button>
								</Grid>
							</Grid>
							<NotificationsPopover
								anchorEl={notificationsRef.current}
								notifications={notifications}
								persistentNotifications={persistentNotifications}
								onClose={handleNotificationsClose}
								open={openNotifications}
							/>
						</React.Fragment>
					)}
					{isTabletMode && (
						<React.Fragment>
							<Grid
								container
								direction="column"
								justify="flex-end"
								alignItems="flex-end"
							>
								<Grid item xs={12}>
									<TabletSandwichMenu></TabletSandwichMenu>
									{/* <Button
										className={classes.logoutButton}
										color='inherit'
										onClick={tabletMenuButtonClick}>
										<MenuIcon
											className={classes.logoutIcon}
											// onClick={tabletMenuButtonClick}
										/>
									</Button> */}
								</Grid>
							</Grid>
						</React.Fragment>
					)}
					<ConfirmationModal {...confirmationPopupState} />
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

function mapState(state: any) {
	return state;
}

const actionCreators = {
	logout: actions.logout,
};

const connectedTopBar = connect(mapState, actionCreators)(TopBar);
export { connectedTopBar as TopBar };
