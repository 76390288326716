import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IEvaluatedPolicies,
  IEvaluatedPolicy,
  ICharges,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
    },
  })
);

interface IRefundProps {
  terminationId: string;
  page: string;
  pageKey: number;
}

const RefundPolicy = (props: IRefundProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [terminationId, setTerminationId] = React.useState<string>("");
  const [evaluatedPolicy, setEvaluatedPolicy] =
    React.useState<IEvaluatedPolicies>();
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setTerminationId(props.terminationId);
    GetEvaluateRefundPolicies(props.terminationId);
  }, [props]);

  const GetEvaluateRefundPolicies = (terminationId: string) => {
    terminationApi.EvaluateRefundPolicies(terminationId).then(
      (response: any) => {
        if (response && response.result) {
          setEvaluatedPolicy(response.result);
        }
      },
      (exception: any) => {}
    );
  };

  return (
    <React.Fragment>
      <Box>        
        {evaluatedPolicy?.evaluatedPolicies.map((p: IEvaluatedPolicy) => (
          <Box>
            <div>Refund calculation as per {p.refundPolicyDescription}</div>
            <div>
              THIS REFUND POLICY IS THE ONE MOST BENEFICIAL TO THE STUDENT.
              THEREFORE, IT IS THE ONE USED.
            </div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Policy details:</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Refund period:</TableCell>
                  <TableCell>{p.refundPeriodDescription}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rule applicable to student:</TableCell>
                  <TableCell>{p.ruleNumberApplicableToStudent}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Withdrawal period:</TableCell>
                  <TableCell>{p.withdrawalPeriod}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Program/Period completion (if applicable as per rule):
                  </TableCell>
                  <TableCell>
                    {p.programCompletionRequiredByRuleAppliction}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Program/Period completion calculated for the student (if
                    applicable as per rule):
                  </TableCell>
                  <TableCell>
                    {p.calculatedProgramCompletionForStudent}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rule applicable reason:</TableCell>
                  <TableCell>{p.ruleApplicableMessage}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Amount earned as per rule:</TableCell>
                  <TableCell>{p.earnedCalculationMessage}</TableCell>
                </TableRow>
                {props.page == "termination" ? (
                  <TableRow>
                    <TableCell>Total contracted charges:</TableCell>
                    <TableCell>
                      {p.totalChargesEarnedAndTerminationFee}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
            {props.page == "institutional" ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Posted charges</TableCell>
                    <TableCell>
                      Charges earned for the period completed
                    </TableCell>
                    <TableCell>
                      Charges earned for the last incomplete period
                    </TableCell>
                    <TableCell>Cumulative charges earned</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {p?.charges.map((c: ICharges) => (
                    <TableRow>
                      <TableCell>{c.category}</TableCell>
                      <TableCell>{c.postedCharges}</TableCell>
                      <TableCell>
                        {c.chargesEarnedForCompletedPeriods}
                      </TableCell>
                      <TableCell>{c.chargesEarnedForLastPeriod}</TableCell>
                      <TableCell>{c.cumulativeChargesEarned}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>Total earned</TableCell>
                    <TableCell>
                      {p.totalChargesEarnedAndTerminationFee}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}
            <div>Determining the refund amount</div>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Current Balance</TableCell>
                  <TableCell>{p.currentBalance}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Add Return to Title IV</TableCell>
                  <TableCell>{p.returnToTitleIV}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Post Withdrawal Disbursement </TableCell>
                  <TableCell>{p.postWithdrawalDisbursement}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Unearned Charges</TableCell>
                  <TableCell>{p.unearnedCharges}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Termination Fee</TableCell>
                  <TableCell>{p.terminationFee}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>New Student Balance</TableCell>
                  <TableCell>{p.newStudentBalance}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default RefundPolicy;
