import { ISprGradRates2YrInstDetailParams } from "../../../interfaces/reports/ISprGradRates2YrInstDetailParams";
import { ToBlob } from "../../../utils/base64Helper";
import { Download } from "../../../utils/file";
import API from "../../apiWrapper";

export const getSprGradRates2YrInstSection3DetailReport = (
    params: ISprGradRates2YrInstDetailParams,
    isPreview: boolean = false
  ): Promise<any> => {
    return API()
      .post("/Reports/IPEDS/GetSprGradRates2YrInstSection3DetailReport", params)
      .then(
        (response:any) => {
          if (isPreview) {
            return response.data.base64Data;
          }
          const extension = "pdf";
          var fileName =
            "SprGradRates2YrInstSection3Detail" + "." + extension;
  
          Download(ToBlob(response.data.base64Data), fileName);
          return undefined;
        },
        (_) => {
          return null;
        }
      );
  };