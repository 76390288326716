import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
}

const ResultsTab5 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [model, setModel] = React.useState<ITerminationResult>();
  const [override, setOverride] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setModel(props.model);   
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>5. Amount of Unearned Title IV Aid Due from the School</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} sm={6} xs={12}>
                  <div>
                    <b>L. Institutional charges for the period</b>
                  </div>
                  <div style={{ paddingTop: 300 }}>
                    <b> Total Institutional Charges</b>
                    <div>(Add all the charges together)</div>
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="right" style={{ width: 100 }}>
                            Tuition
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="tuitionFee"
                              key="tuitionFee"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                              readOnly={override == true ? false : true}
                              defaultValue={model?.tuitionFee}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "tuitionFee",
                                  v.target.value
                                );
                              }}
                              error={!!data.errors.tuitionFee}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.tuitionFee
                                  ? (data.errors.tuitionFee as FieldError)
                                      .message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">Housing</TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="roomFee"
                              key="roomFee"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                              readOnly={override == true ? false : true}
                              defaultValue={model?.roomFee}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange("roomFee", v.target.value);
                              }}
                              error={!!data.errors.roomFee}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.roomFee
                                  ? (data.errors.roomFee as FieldError).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">Food</TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="boardFee"
                              key="boardFee"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                              readOnly={override == true ? false : true}
                              defaultValue={model?.boardFee}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange("boardFee", v.target.value);
                              }}
                              error={!!data.errors.boardFee}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.boardFee
                                  ? (data.errors.boardFee as FieldError).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">Other</TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="otherFee"
                              key="otherFee"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                              readOnly={override == true ? false : true}
                              defaultValue={model?.otherFee}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange("otherFee", v.target.value);
                              }}
                              error={!!data.errors.otherFee}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.otherFee
                                  ? (data.errors.otherFee as FieldError).message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <b>L. </b>
                          </TableCell>
                          <TableCell>
                            <InputField
                              type={InputType.CURRENCY}
                              label=""
                              name="boxLResult"
                              key="boxLResult"
                              decimal
                              thousandSeparator
                              inputProps={{style: { textAlign: 'right' }}}
                              readOnly={override == true ? false : true}
                              defaultValue={model?.boxLResult}
                              onBlur={(
                                v: React.FocusEvent<HTMLInputElement>
                              ) => {
                                handleFieldOnChange(
                                  "boxLResult",
                                  v.target.value
                                );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <React.Fragment>
                                    <div className={classes.leftLabel}>$</div>
                                  </React.Fragment>
                                ),
                              }}
                              error={!!data.errors.boxLResult}
                              inputRef={data.register({
                                required: "Field is required.",
                              })}
                              helperText={
                                data.errors.boxLResult
                                  ? (data.errors.boxLResult as FieldError)
                                      .message
                                  : undefined
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <b>M. Percentage of unearned Title IV aid</b>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b></b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell align="center">
                        <b>Box H</b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>100%</b>
                      </TableCell>
                      <TableCell>
                        <b>-</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="txt5Boxh"
                          key="txt5Boxh"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={override == true ? false : true}
                          defaultValue={model?.txt5Boxh}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("txt5Boxh", v.target.value);
                          }}
                          error={!!data.errors.txt5Boxh}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                <div className={classes.rightLabel}>%</div>
                              </React.Fragment>
                            ),
                          }}
                          helperText={
                            data.errors.txt5Boxh
                              ? (data.errors.txt5Boxh as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <b>=</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="boxMResult"
                          key="boxMResult"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={override == true ? false : true}
                          defaultValue={model?.boxMResult}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("boxMResult", v.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                <div className={classes.leftLabelBox}>M.</div>
                                <div className={classes.rightLabel}>%</div>
                              </React.Fragment>
                            ),
                          }}
                          error={!!data.errors.boxMResult}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.boxMResult
                              ? (data.errors.boxMResult as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div>
                  <b>N. Amount of unearned charges</b>
                </div>
                <div>
                  Multiply institutional charges for the period (Box L) times
                  the percentage of unearned Title IV aid (Box M)
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <b>Box L</b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell align="center">
                        <b>Box M</b>
                      </TableCell>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <InputField
                          type={InputType.TEXT}
                          label=""
                          name="txt5Boxl"
                          key="txt5Boxl"
                          readOnly={true}
                          defaultValue={model?.txt5Boxl}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("txt5Boxl", v.target.value);
                          }}
                          error={!!data.errors.txt5Boxl}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.txt5Boxl
                              ? (data.errors.txt5Boxl as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <b>x</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="txt5Boxm"
                          key="txt5Boxm"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={true}
                          defaultValue={model?.txt5Boxm}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("txt5Boxm", v.target.value);
                          }}
                          error={!!data.errors.txt5Boxm}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                <div className={classes.rightLabel}>%</div>
                              </React.Fragment>
                            ),
                          }}
                          helperText={
                            data.errors.txt5Boxm
                              ? (data.errors.txt5Boxm as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <b>=</b>
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.NUMBER}
                          label=""
                          name="boxNResult"
                          key="boxNResult"
                          decimal
                          thousandSeparator
                          inputProps={{style: { textAlign: 'right' }}}
                          readOnly={override == true ? false : true}
                          defaultValue={model?.boxNResult}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("boxNResult", v.target.value);
                          }}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                <div className={classes.leftLabelBox}>N.</div>
                                <div className={classes.leftLabel}>$</div>
                              </React.Fragment>
                            ),
                          }}
                          error={!!data.errors.boxNResult}
                          inputRef={data.register({
                            required: "Field is required.",
                          })}
                          helperText={
                            data.errors.boxNResult
                              ? (data.errors.boxNResult as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div>
                  <b>O. Amount for school to return</b>
                </div>
                <div>
                  Compare the amount of Title IV aid to be returned (Box K) to
                  amount of unearned charges (Box N), and enter the lesser
                  amount.
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <InputField
                  type={InputType.NUMBER}
                  label=""
                  name="boxOResult"
                  key="boxOResult"
                  decimal
                  thousandSeparator
                  inputProps={{style: { textAlign: 'right' }}}
                  readOnly={override == true ? false : true}
                  defaultValue={model?.boxOResult}
                  onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                    handleFieldOnChange("boxOResult", v.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <React.Fragment>
                        <div className={classes.leftLabelBox} style={{paddingLeft: "7px"}}>O.</div>
                        <div className={classes.leftLabel}>$</div>
                      </React.Fragment>
                    ),
                  }}
                  error={!!data.errors.boxOResult}
                  inputRef={data.register({
                    required: "Field is required.",
                  })}
                  helperText={
                    data.errors.boxOResult
                      ? (data.errors.boxOResult as FieldError).message
                      : undefined
                  }
                />
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab5;
