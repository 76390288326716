import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import FutureChargesPopup from "./FutureChargesPopup";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    rightAlign: {
      textAlign: "right",
    },
  })
);

interface ITerminationProps {
  student: StudentProfile;
  enrollment: IStudentEnrollments | undefined;
  enrollmentId: string;
  terminationId: string;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setIsR2t4InputExists: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
}

const R2T4InputTab = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [campusId, setCampusId] = React.useState<string>(props.campusId);
  const [enrollmentId, setEnrollmentId] = React.useState<string>(
    props.enrollmentId
  );
  const [student, setStudent] = React.useState<StudentProfile>(props.student);
  const [terminationId, setTerminationId] = React.useState<string>(
    props.terminationId
  );
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [titleIVGrants, setTitleIVGrants] = React.useState<IR2T4Step1Input>();
  const [r2T4Input, setR2T4Input] = React.useState<IR2T4Input>(
    new IR2T4Input()
  );
  const [programVersionDetail, setProgramVersionDetail] =
    React.useState<IProgramVersionDetail>();
  const [scheduleHoursToComplete, setScheduleHoursToComplete] =
    React.useState<IScheduleHoursToComplete>();
  const [userAllowedToOverrideR2T4Input, setUserAllowedToOverrideR2T4Input] =
    React.useState<boolean>(false);
  const [override, setOverride] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setR2T4Input((prev) => ({
      ...prev,
      terminationId: props.terminationId,
    }));
    GetIsR2T4InputExists(props.terminationId);
    setCampusId(props.campusId);
    setEnrollmentId(props.enrollmentId);
    setEnrollment(props.enrollment);
    setStudent(props.student);
    setTerminationId(props.terminationId);
    GetUserAllowedToOverrideR2T4Input();    
  }, [props]);

  const GetIsR2T4InputExists = (terminationId: string) => {
    terminationApi.GetIsR2T4InputExists(terminationId).then(
      (response: any) => {
        if (response && response == true) {
          getCalculateR2T4Input(terminationId, true);
        } else {
          getFutureCharges(terminationId);
          getDefaultR2T4Input();
        }
      },
      (exception: any) => {}
    );
  };

  const getFutureCharges = (
    terminationId: string
  ) => {
    terminationApi
      .GetFutureCharges(terminationId)
      .then(
        (response: any) => {
          if (response && response?.result.length == 0 && popUpOpen == false) {
            setPopUpOpen(true);
          }
        },
        (exception: any) => {}
      );
  };

  const getCalculateR2T4Input = (
    terminationId: string,
    takeOverriddenIfExists: boolean
  ) => {
    terminationApi
      .GetCalculateR2T4Input(terminationId, takeOverriddenIfExists)
      .then(
        (response: any) => {
          if (response) {
            setR2T4Input({ ...response });
            setOverride(response.isOverriden);
          }
        },
        (exception: any) => {}
      );
  };

  const getDefaultR2T4Input = async () => {
    if (enrollment?.lastDateAttended != null && terminationId != EmptyGuid) {
      try {
        const [step1Response, hoursResponse, programVersionResponse] = await Promise.all([
          terminationApi.GetCalculateR2T4Step1Input(enrollmentId, enrollment?.lastDateAttended),
          terminationApi.GetHoursScheduledToComplete(enrollmentId),
          terminationApi.GetProgramVersionDetailByEnrollmentId(enrollmentId),
        ]);       
  
        if (step1Response) {
          setTitleIVGrants(step1Response);
          const updatedInput = { ...r2T4Input };
          copyMatchingProperties(step1Response, updatedInput);
          setR2T4Input(updatedInput);
        }
  
        if (hoursResponse) {
          setScheduleHoursToComplete(hoursResponse);
          setR2T4Input((prev) => ({
            ...prev,
            completedTime: hoursResponse.hoursScheduledToComplete,
            totalTime: hoursResponse.totalHoursInPeriod,
          }));
        }
  
        if (programVersionResponse) {
          setProgramVersionDetail(programVersionResponse);
          setR2T4Input((prev) => ({
            ...prev,
            withdrawalDate: programVersionResponse.withdrawalDate,
          }));
        }

        setR2T4Input((prev) => ({
          ...prev,
          ticketNumber: null,
          isOverriden:false,
        }));
        setOverride(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  

  const handleOverrideChange = (value: boolean) => {
    if (value == false) {
      props.setConfirmationDialog({
        open: true,
        message: "All the changes made would be discarded. Are you sure?",
        onOk: () => {
          terminationApi
          .DeleteOverride(terminationId)
          .then(
            (response: any) => {
              if (response) {
                getDefaultR2T4Input();
                setOverride(value);
              }
            },
            (exception: any) => {}
          );         
        },
        onCancel: () => setLoading(false),
      });
    } else {
      setOverride(value);
    }
  };

  const copyMatchingProperties = (
    source: IR2T4Step1Input,
    target: IR2T4Input
  ) => {
    target.pellGrantDisbursed = source.pellGrantDisbursed;
    target.pellGrantCouldDisbursed = source.pellGrantCouldDisbursed;
    target.fseogDisbursed = source.fseogDisbursed;
    target.fseogCouldDisbursed = source.fseogCouldDisbursed;
    target.teachGrantDisbursed = source.teachGrantDisbursed;
    target.teachGrantCouldDisbursed = source.teachGrantCouldDisbursed;
    target.iraqAfgGrantDisbursed = source.iraqAfgGrantDisbursed;
    target.iraqAfgGrantCouldDisbursed = source.iraqAfgGrantCouldDisbursed;
    target.unsubLoanNetAmountDisbursed = source.unsubLoanNetAmountDisbursed;
    target.unsubLoanNetAmountCouldDisbursed =
      source.unsubLoanNetAmountCouldDisbursed;
    target.subLoanNetAmountDisbursed = source.subLoanNetAmountDisbursed;
    target.subLoanNetAmountCouldDisbursed =
      source.subLoanNetAmountCouldDisbursed;
    target.perkinsLoanDisbursed = source.perkinsLoanDisbursed;
    target.perkinsLoanCouldDisbursed = source.perkinsLoanCouldDisbursed;
    target.directGraduatePlusLoanDisbursed =
      source.directGraduatePlusLoanDisbursed;
    target.directGraduatePlusLoanCouldDisbursed =
      source.directGraduatePlusLoanCouldDisbursed;
    target.directParentPlusLoanDisbursed = source.directParentPlusLoanDisbursed;
    target.directParentPlusLoanCouldDisbursed =
      source.directParentPlusLoanCouldDisbursed;
    target.creditBalanceRefunded =
      source.step5Inputs.titleIVCreditBalanceRefunded;
    target.tuitionFee = source.step5Inputs.tuition;
    target.roomFee = source.step5Inputs.room;
    target.boardFee = source.step5Inputs.board;
    target.otherFee = source.step5Inputs.other;
    //target.isTuitionChargedByCourse = source.step5Inputs.isTuitionChargedByCourse;
    target.isTuitionChargedByPaymentPeriod =
      source.step5Inputs.isTuitionChargedByPaymentPeriod;
    target.nonInstitutionalBooksAndSupplies =
      source.step5Inputs.nonInstitutionalBooksAndSupplies;
  };

  const GetUserAllowedToOverrideR2T4Input = () => {
    terminationApi.GetIsUserAllowedToOverrideR2T4InputOrResultsInfo().then(
      (response: any) => {
        setUserAllowedToOverrideR2T4Input(response);
      },
      (exception: any) => {}
    );
  };

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (r2T4Input) {
      let updatedModel = r2T4Input;
      (updatedModel as any)[fieldId] = value;
      setR2T4Input({ ...updatedModel });
    }
  };

  const handlePercentageFieldOnChange = (fieldId: string, value: any) => {
    if (r2T4Input) {
      let updatedModel = r2T4Input;
      (updatedModel as any)[fieldId] = value;
      setR2T4Input({ ...updatedModel });
    }
  };

  const unFormattedNumber = (formattedNumber: any) => {
    const cleanedInput = (formattedNumber + "").replace(/[^\d.-]/g, "");
    const result = Number(cleanedInput);
    return result;
  };

  const onAmountChanged = (studentPaymentAmount: any) => {
    return (
      unFormattedNumber(studentPaymentAmount) > 0 ||
      "Amount must be greater than 0"
    );
  };

  const onSubmit = async (nextTab: any) => {
    const validation = await data.triggerValidation();
    if (validation) {
      if (override == false ) {
        if (r2T4Input?.r2T4InputId == EmptyGuid || r2T4Input?.isOverriden == true) {
          terminationApi.PostR2T4Input(r2T4Input).then(
            (response: any) => {
              if (response) {
                setR2T4Input(response);
                props.setIsR2t4InputExists(true);
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo:
                      "All the student termination details specified is saved",
                  };
                });
                if (nextTab) props.setTab(2);
              }
            },
            (exception: any) => {}
          );
        } else {
          if (r2T4Input) {
            terminationApi.PutR2T4Input(r2T4Input).then(
              (response: any) => {
                if (response) {
                  setR2T4Input(response);
                  props.setIsR2t4InputExists(true);
                  props.setSnackBar((props: any) => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo:
                        "All the student termination details specified is updated",
                    };
                  });
                  if (nextTab) props.setTab(2);
                }
              },
              (exception: any) => {}
            );
          }
        }
      } else {
        if (r2T4Input?.r2T4InputId == EmptyGuid || r2T4Input?.isOverriden == false) {
          terminationApi.PostInputOverride(r2T4Input).then(
            (response: any) => {
              if (response) {
                setR2T4Input(response);
                props.setIsR2t4InputExists(true);
                if(!nextTab)
                {
                  props.setSnackBar((props: any) => {
                    return {
                      variant: "success",
                      showSnackBar: true,
                      messageInfo:
                        "All the student termination details specified is saved",
                    };
                  });
                }                
                if (nextTab)
                {
                  props.setTab(2);
                } 
              }
            },
            (exception: any) => {}
          );
        } else {
          if (r2T4Input) {
            terminationApi.PutInputOverride(r2T4Input).then(
              (response: any) => {
                if (response) {
                  setR2T4Input(response);
                  props.setIsR2t4InputExists(true);
                  if(!nextTab)
                  {
                    props.setSnackBar((props: any) => {
                      return {
                        variant: "success",
                        showSnackBar: true,
                        messageInfo:
                          "All the student termination details specified is saved",
                      };
                    });
                  }  
                  if (nextTab) props.setTab(2);
                }
              },
              (exception: any) => {}
            );
          }
        }
      }
    }
  };

  const onCancel = async (confirm?: boolean) => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to cancel the student termination process? All current data entered, including the previously saved data, will be lost",
      onOk: () => deleteTermination(),
      onCancel: () => setLoading(false),
    });
  };

  const onApprove = () => {};

  const deleteTermination = () => {
    if (terminationId != EmptyGuid) {
      props.deleteTermination(terminationId);
    }
  };

  const [popUpOpen, setPopUpOpen] = React.useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={8} sm={8} xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item md={4} sm={4} xs={12}>
              <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>SSN: </b>
              {student?.ssn}
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <b>Enrollment: </b>
              {enrollment?.programVersionDescription}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <b>1. Title IV aid</b>
              </Typography>
              <Tooltip title="Provide value for aid disbursed or aid that could have been disbursed for any grant or loan program. It is required for R2T4 calculation.">
                <IconButton
                  size="small"
                  style={{ position: "absolute", right: 50, top: 10 }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Grant Programs</b>
                        </TableCell>
                        <TableCell align="right">Amount disbursed</TableCell>
                        <TableCell align="right">
                          Amount that could have been disbursed
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>1. Pell Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantDisbursed"
                            key="pellGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.pellGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantDisbursed
                                ? (data.errors.pellGrantDisbursed as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="pellGrantCouldDisbursed"
                            key="pellGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.pellGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "pellGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.pellGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.pellGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .pellGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>2. FSEOG</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogDisbursed"
                            key="fseogDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.fseogDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogDisbursed
                                ? (data.errors.fseogDisbursed as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="fseogCouldDisbursed"
                            key="fseogCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.fseogCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "fseogCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.fseogCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.fseogCouldDisbursed
                                ? (
                                    data.errors
                                      .fseogCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>3. TEACH Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantDisbursed"
                            key="teachGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.teachGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantDisbursed
                                ? (
                                    data.errors
                                      .teachGrantDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="teachGrantCouldDisbursed"
                            key="teachGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.teachGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "teachGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.teachGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.teachGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .teachGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>4. Iraq and Afghanistan Service Grant</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantDisbursed"
                            key="iraqAfgGrantDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.iraqAfgGrantDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantDisbursed
                                ? (
                                    data.errors
                                      .iraqAfgGrantDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="iraqAfgGrantCouldDisbursed"
                            key="iraqAfgGrantCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.iraqAfgGrantCouldDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "iraqAfgGrantCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.iraqAfgGrantCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.iraqAfgGrantCouldDisbursed
                                ? (
                                    data.errors
                                      .iraqAfgGrantCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title IV Loan Programs</b>
                        </TableCell>
                        <TableCell align="right">
                          Net amount disbursed
                        </TableCell>
                        <TableCell align="right">
                          Net amount that could have been disbursed
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>5. Unsubsidized Direct Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="unsubLoanNetAmountDisbursed"
                            key="unsubLoanNetAmountDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.unsubLoanNetAmountDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "unsubLoanNetAmountDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.unsubLoanNetAmountDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.unsubLoanNetAmountDisbursed
                                ? (
                                    data.errors
                                      .unsubLoanNetAmountDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="unsubLoanNetAmountCouldDisbursed"
                            key="unsubLoanNetAmountCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.unsubLoanNetAmountCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "unsubLoanNetAmountCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.unsubLoanNetAmountCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.unsubLoanNetAmountCouldDisbursed
                                ? (
                                    data.errors
                                      .unsubLoanNetAmountCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>6. Subsidized Direct Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subLoanNetAmountDisbursed"
                            key="subLoanNetAmountDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.subLoanNetAmountDisbursed}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subLoanNetAmountDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.subLoanNetAmountDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subLoanNetAmountDisbursed
                                ? (
                                    data.errors
                                      .subLoanNetAmountDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="subLoanNetAmountCouldDisbursed"
                            key="subLoanNetAmountCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.subLoanNetAmountCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "subLoanNetAmountCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.subLoanNetAmountCouldDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.subLoanNetAmountCouldDisbursed
                                ? (
                                    data.errors
                                      .subLoanNetAmountCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>7. Direct Graduate PLUS Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directGraduatePlusLoanDisbursed"
                            key="directGraduatePlusLoanDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.directGraduatePlusLoanDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directGraduatePlusLoanDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directGraduatePlusLoanDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directGraduatePlusLoanDisbursed
                                ? (
                                    data.errors
                                      .directGraduatePlusLoanDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directGraduatePlusLoanCouldDisbursed"
                            key="directGraduatePlusLoanCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.directGraduatePlusLoanCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directGraduatePlusLoanCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directGraduatePlusLoanCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directGraduatePlusLoanCouldDisbursed
                                ? (
                                    data.errors
                                      .directGraduatePlusLoanCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>8. Direct Parent PLUS Loan</b>
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directParentPlusLoanDisbursed"
                            key="directParentPlusLoanDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.directParentPlusLoanDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directParentPlusLoanDisbursed",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.directParentPlusLoanDisbursed}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directParentPlusLoanDisbursed
                                ? (
                                    data.errors
                                      .directParentPlusLoanDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="directParentPlusLoanCouldDisbursed"
                            key="directParentPlusLoanCouldDisbursed"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.directParentPlusLoanCouldDisbursed
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "directParentPlusLoanCouldDisbursed",
                                v.target.value
                              );
                            }}
                            error={
                              !!data.errors.directParentPlusLoanCouldDisbursed
                            }
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.directParentPlusLoanCouldDisbursed
                                ? (
                                    data.errors
                                      .directParentPlusLoanCouldDisbursed as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>
                <b>2. Percentage of period completed</b>
              </Typography>
              <Tooltip title="Provide values for Withdrawal date, Hours scheduled to complete and Total hours in the period. They are required for R2T4 calculation.">
                <IconButton
                  size="small"
                  style={{ position: "absolute", right: 50, top: 10 }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: 300 }}></TableCell>
                        <TableCell style={{ width: 300 }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Withdrawal date*</TableCell>
                        <TableCell>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              id="withdrawalDate"
                              name="withdrawalDate"
                              label=""
                              disabled={override == true ? false : true}
                              value={
                                r2T4Input?.withdrawalDate
                                  ? r2T4Input.withdrawalDate
                                  : programVersionDetail?.withdrawalDate
                              }
                              onChange={(e: any, value: any) =>
                                handleFieldOnChange(
                                  "withdrawalDate",
                                  value ? value : undefined
                                )
                              }
                              className={classes.startDateField}
                              KeyboardButtonProps={{
                                "aria-label": "report date",
                              }}
                              helperText={
                                data.errors["withdrawalDate"]
                                  ? (
                                      data.errors[
                                        "withdrawalDate"
                                      ] as FieldError
                                    ).message
                                  : undefined
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Hours scheduled to complete *</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="completedTime"
                            key="completedTime"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.completedTime
                                ? r2T4Input?.completedTime
                                : null
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handlePercentageFieldOnChange(
                                "completedTime",
                                v.target.value
                              );
                            }}
                            /* error={!!data.errors.completedTime}
                            inputRef={data.register({
                              required: "Field is required.",
                            })} */
                            helperText={
                              data.errors.completedTime
                                ? (data.errors.completedTime as FieldError)
                                    .message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total hours in period *</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="totalTime"
                            key="totalTime"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={
                              r2T4Input?.totalTime ? r2T4Input?.totalTime : null
                            }
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handlePercentageFieldOnChange(
                                "totalTime",
                                v.target.value
                              );
                            }}
                            /*  error={!!data.errors.totalTime}
                            inputRef={data.register({
                              required: "Field is required.",
                            })} */
                            helperText={
                              data.errors.totalTime
                                ? (data.errors.totalTime as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>
                <b>3. Institutional charges posted</b>
              </Typography>
              <Tooltip title="A value equal to or greater than zero for any one of the charges listed is required.">
                <IconButton
                  size="small"
                  style={{ position: "absolute", right: 50, top: 10 }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead></TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Tuition charged by payment period</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  r2T4Input?.isTuitionChargedByPaymentPeriod
                                }
                                readOnly={override == true ? false : true}
                                onChange={(e) =>
                                  handleFieldOnChange(
                                    "isTuitionChargedByPaymentPeriod",
                                    e.target.checked
                                  )
                                }
                                color="primary"
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Title IV credit balance refunded to student
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="titleIVCreditBalanceRefunded"
                            key="creditBalanceRefunded"
                            inputProps={{style: { textAlign: 'right' }}}
                            decimal
                            thousandSeparator
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.creditBalanceRefunded}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange(
                                "creditBalanceRefunded",
                                v.target.value
                              );
                            }}
                            error={!!data.errors.creditBalanceRefunded}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.creditBalanceRefunded
                                ? (
                                    data.errors
                                      .creditBalanceRefunded as FieldError
                                  ).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Amount</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tuition</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="tuitionFee"
                            key="tuitionFee"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.tuitionFee}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("tuitionFee", v.target.value);
                            }}
                            error={!!data.errors.tuitionFee}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.tuitionFee
                                ? (data.errors.tuitionFee as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Housing</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="roomFee"
                            key="roomFee"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.roomFee}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("roomFee", v.target.value);
                            }}
                            error={!!data.errors.roomFee}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.roomFee
                                ? (data.errors.roomFee as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Food</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="boardFee"
                            key="boardFee"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.boardFee}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boardFee", v.target.value);
                            }}
                            error={!!data.errors.boardFee}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boardFee
                                ? (data.errors.boardFee as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Other</TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.CURRENCY}
                            label=""
                            name="otherFee"
                            key="otherFee"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={r2T4Input?.otherFee}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("otherFee", v.target.value);
                            }}
                            error={!!data.errors.otherFee}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.otherFee
                                ? (data.errors.otherFee as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Grid>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Ticket # *</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              //checked={r2T4Input.isOverriden}
                              checked={override}
                              disabled={
                                userAllowedToOverrideR2T4Input == true
                                  ? false
                                  : true
                              }
                              onChange={(e) => {
                                /* handleFieldOnChange(
                                  "isOverriden",
                                  e.target.checked
                                ); */
                                handleOverrideChange(e.target.checked);
                              }}
                              color="primary"
                            />
                          }
                          label="Override"
                        />
                      </TableCell>
                      <TableCell>
                        <InputField
                          type={InputType.TEXT}
                          label=""
                          name="ticketNumber"
                          key="ticketNumber"
                          readOnly={override == true ? false : true}
                          defaultValue={r2T4Input?.ticketNumber}
                          onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                            handleFieldOnChange("ticketNumber", v.target.value);
                          }}
                          error={!!data.errors.ticketNumber}
                          inputRef={data.register({
                            required: override == true ? true : false,
                          })}
                          helperText={
                            data.errors.ticketNumber
                              ? (data.errors.ticketNumber as FieldError).message
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Grid spacing={1} item md={12} sm={12} xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.setTab(0)}
              style={{ marginRight: "8px" }}
            >
              &lt; Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onSubmit(true)}
              style={{ marginRight: "8px" }}
            >
              Next &gt;
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit(false)}
              style={{ marginRight: "8px" }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onCancel(true)}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onApprove()}
              style={{
                marginRight: "8px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Approve Termination
            </Button>
          </Box>
        </Grid>
      </Grid>
      <FutureChargesPopup terminationId={terminationId} open={popUpOpen} handleClose={setPopUpOpen} isLoading={loading} setLoading={setLoading} setSnackBar={props.setSnackBar} setConfirmationDialog={props.setConfirmationDialog}/>
    </div>
  );
};

export default R2T4InputTab;
