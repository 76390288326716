import React, { useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  Typography,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IR2T4Step1Input,
  IR2T4Input,
  IProgramVersionDetail,
  IScheduleHoursToComplete,
  ITerminationResult,
} from "../../../interfaces/student/termination/IStudentTermination";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CustomCardTitle from "../../../interfaces/common/card/CustomCardTitle";
import { withStyles } from "@material-ui/core/styles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import CurrencyInput from "../../../components/_Layout/Inputs/CurrencyInput";
import InputField from "../../../components/_Layout/Inputs/InputField";
import { InputType } from "../../../constants/uiConstants/inputConstants";
import { colors } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    textField: {
      marginRight: theme.spacing(1),
      width: "100%",
    },
    startDateField: {
      width: "100%",
    },
    leftLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      height: "31px",
      paddingTop: "6px",
    },
    rightLabel: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: "black",
      color: "white",
      textAlign: "center",
      marginRight: "-19px",
      display: "inline-block",
      position: "absolute",
      right:0,
      height: "31px",
      paddingTop: "6px",
    },
    leftLabelBox: {
      width: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      textAlign: "center",
      marginRight: theme.spacing(1),
      display: "inline-block",
      marginLeft: "-23px",
    },
    directionIcon: {
      fontSize: "22px",
    },
  })
);

interface ITerminationProps {
  model: ITerminationResult | undefined;
  setModel: (params: any) => void;
}

const ResultsTab4 = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [model, setModel] = React.useState<ITerminationResult>();
  const [override, setOverride] = React.useState<boolean>(false);
  const data = useForm<any>({ mode: "onBlur" });

  useEffect(() => {
    setModel(props.model);
  }, [props]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    if (model) {
      let updatedModel = model;
      (updatedModel as any)[fieldId] = value;
      setModel({ ...updatedModel });
    }
  };

  return (
    <React.Fragment>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              <b>4. Title IV Aid to be Disbursed or Returned</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    <label className={classes.directionIcon}> ☞ </label>If the amount in Box I is greater than the amount in Box E,
                    go to Item J (post-withdrawal disbursement).
                  </div>
                  <div>
                    <label className={classes.directionIcon}> ☞ </label>If the amount in Box I is less than the amount in Box E, go
                    to Title IV aid to be returned (Item K).
                  </div>
                  <div>
                    <label className={classes.directionIcon}> ☞ </label>If the Amounts in box I and Box E are equal, STOP. No
                    further action is necessary.
                  </div>
                  <div style={{marginTop:"5px"}}>
                    <b>J. Post-withdrawal disbursement</b>
                  </div>
                  <div>
                    From the Amount of Title IV aid earned by the student (Box
                    I) subtract the Total Title IV aid disbursed for the period
                    (Box E). This is the amount of the post-withdrawal
                    disbursement.
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box I</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Box E</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="txt4Ji"
                            key="txt4Ji"
                            readOnly={override == true ? false : true}
                            defaultValue={model?.txt4Ji}
                            inputProps={{style: { textAlign: 'right' }}}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt4Ji", v.target.value);
                            }}
                            error={!!data.errors.txt4Ji}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt4Ji
                                ? (data.errors.txt4Ji as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt4Je"
                            key="txt4Je"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={model?.txt4Je}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt4Je", v.target.value);
                            }}
                            error={!!data.errors.txt4Je}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt4Je
                                ? (data.errors.txt4Je as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxJResult"
                            key="boxJResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={model?.boxJResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxJResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>J.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxJResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxJResult
                                ? (data.errors.boxJResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    <b>Stop here,</b> and enter the amount in "J" in Box 1 on
                    page 3 (Post-withdrawal disbursement tracking sheet).
                  </div>
                  <div>
                    <b>K. Title IV aid to be returned</b>
                  </div>
                  <div>
                    From the Total Title IV aid disbursed for the period (Box E)
                    subtract the amount of Title IV aid earned by the student
                    (Box I). This is the amount of Title IV aid that must be
                    returned.
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <b>Box E</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell align="center">
                          <b>Box I</b>
                        </TableCell>
                        <TableCell style={{ width: 10 }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <InputField
                            type={InputType.TEXT}
                            label=""
                            name="txt4Ke"
                            key="txt4Ke"
                            readOnly={override == true ? false : true}
                            defaultValue={model?.txt4Ke}
                            inputProps={{style: { textAlign: 'right' }}}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt4Ke", v.target.value);
                            }}
                            error={!!data.errors.txt4Ke}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt4Ke
                                ? (data.errors.txt4Ke as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>-</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="txt4Ki"
                            key="txt4Ki"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={model?.txt4Ki}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("txt4Ki", v.target.value);
                            }}
                            error={!!data.errors.txt4Ki}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.txt4Ki
                                ? (data.errors.txt4Ki as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <b>=</b>
                        </TableCell>
                        <TableCell>
                          <InputField
                            type={InputType.NUMBER}
                            label=""
                            name="boxKResult"
                            key="boxKResult"
                            decimal
                            thousandSeparator
                            inputProps={{style: { textAlign: 'right' }}}
                            readOnly={override == true ? false : true}
                            defaultValue={model?.boxKResult}
                            onBlur={(v: React.FocusEvent<HTMLInputElement>) => {
                              handleFieldOnChange("boxKResult", v.target.value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <React.Fragment>
                                  <div className={classes.leftLabelBox}>K.</div>
                                  <div className={classes.leftLabel}>$</div>
                                </React.Fragment>
                              ),
                            }}
                            error={!!data.errors.boxKResult}
                            inputRef={data.register({
                              required: "Field is required.",
                            })}
                            helperText={
                              data.errors.boxKResult
                                ? (data.errors.boxKResult as FieldError).message
                                : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );
};

export default ResultsTab4;
