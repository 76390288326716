import { IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam } from "../../../interfaces/reports/IPEDSWinterStudentFAMilitaryBenefitsDetailSummary";
import { ToBlob } from "../../../utils/base64Helper";
import { Download } from "../../../utils/file";
import API from "../../apiWrapper";

export const getWinterStudentFAMilitaryDetailSummaryReport = (
    params: IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam,
    isPreview: boolean = false
): Promise<any> => {
    console.clear();
    console.log(params)
    return API()
        .post("/Reports/IPEDS/WinterStudentFAMilitaryBenefitsDetailAndSummary", params)
        .then(
            (response: any) => {
                if (isPreview) {
                    return response.data.base64Data;
                }
                const extension = "pdf";
                var fileName =
                    "MyReport" + "." + extension;

                Download(ToBlob(response.data.base64Data), fileName);
                return undefined;
            },
            (_) => {
                return null;
            }
        );
};