export interface IProgramCatalogGeneralDetails {
  id: string;
  name: string;
  statusId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  hoursInProgram?: number;
  creditsInProgram?: number;
  faProgramCredits?: number;
  creditsInAcademicYear?: number;
  weeksInAcademicYear?: number;
  weeksInProgram?: number;
  fullTimeDefinition: number;
  addDropPeriod?: number;
  hoursInAcademicYear?: number;
  maxTimeToCompleteProgramPct?: number;
  maximumTimeFrameProgramCredits?:number;
  maximumTimeFrameOfProgramVersion?:number;
  maxTimeToCompleteProgramWeeks?: number;
  minimumAttendanceAllowed?: number;
  paymentPeriodsPerAcademicYear?: number;
  distanceEducationStatusId: number;
  isContinuingEducation?: boolean;
  isClockHour: boolean;
  programId?: string;
  accreditingAgencyId: number;
  meetsLicensureRequirements?:string | null;
}

export class ProgramCatalogGeneralDetails
  implements IProgramCatalogGeneralDetails
{
  id: string = "";
  name: string = "";
  statusId: string = "";
  effectiveStartDate: Date = new Date();
  effectiveEndDate: Date = new Date();
  creditsInProgram?: number = 0;
  faProgramCredits?: number = 0;
  creditsInAcademicYear?: number = 0;
  hoursInProgram?: number = 0;
  fullTimeDefinition: number = 0;
  addDropPeriod?: number = 0;
  weeksInProgram?: number = 0;
  hoursInAcademicYear?: number = 0;
  weeksInAcademicYear?: number = 0;
  maximumTimeFrameOfProgramVersion?: number = 0;
  maxTimeToCompleteProgramPct?: number = 0;
  maxTimeToCompleteProgramWeeks?: number = 0;
  minimumAttendanceAllowed?: number = 0;
  paymentPeriodsPerAcademicYear?: number = 0;
  distanceEducationStatusId: number = 0;
  /* registrationBy?: number; */
  isContinuingEducation?: boolean;
  isClockHour: boolean = false;
  programId?: string = "";
  accreditingAgencyId:number = 0;
  meetsLicensureRequirements?: string | undefined | null = null;
}
