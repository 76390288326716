import React, { useState, useEffect, useDebugValue } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Switch,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormContext, useForm, FieldError } from "react-hook-form";
import {
  IStudentTermination,
  IStudentEnrollments,
  IPeriodTypes,
} from "../../../interfaces/student/termination/IStudentTermination";
import * as terminationApi from "../../../api/student/termination/studentTerminationApi";
import TerminationEnrollmentStatusAutoComplete from "../../../components/AutoComplete/TerminationEnrollmentStatusAutoComplete";
import DropReasonAutoComplete from "../../../components/AutoComplete/DropReasonAutoComplete";
import { StudentProfile } from "../../../interfaces/student/profile/StudentProfile";
import { EmptyGuid } from "../../../utils/constants";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100%",
      paddingTop: theme.spacing(3),
      overflowX: "hidden",
      overflowY: "auto",
    },
    startDateField: {
      width: "100%",
    },
    saveButton: {
      width: 150,
    },
    divider: {
      backgroundColor: theme.palette.site.primary,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    switch: {
      marginLeft: "0px",
      paddingTop: theme.spacing(2),
    },
    fieldSet: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ITerminationProps {
  student: StudentProfile;
  enrollment?: IStudentEnrollments;
  enrollments: IStudentEnrollments[];
  periodTypes: IPeriodTypes[];
  enrollmentId: string;
  setEnrollmentId: any;
  setTerminationId: any;
  campusId: string;
  setConfirmationDialog: (params: any) => void;
  setSnackBar: (params: any) => void;
  setTab: (params: any) => void;
  deleteTermination: (params: any) => void;
}

const TerminationDetailTab = (props: ITerminationProps) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [campusId, setCampusId] = React.useState<string>(props.campusId);
  const [enrollment, setEnrollment] = React.useState<IStudentEnrollments>();
  const [enrollmentId, setEnrollmentId] = React.useState<string>(
    props.enrollmentId
  );
  const [student, setStudent] = React.useState<StudentProfile>(props.student);
  const [periodTypes, setPeriodTypes] = React.useState<IPeriodTypes[]>([]);
  const [enrollments, setEnrollments] = React.useState<IStudentEnrollments[]>(
    props.enrollments
  );
  const data = useForm<any>({ mode: "onBlur" });

  var defaultTermination: IStudentTermination = {
    terminationId: EmptyGuid,
    studentEnrollmentId: "",
    statusCodeId: "",
    dropReasonId: "",
    dateWithdrawalDetermined: undefined as Date | undefined,
    lastDateAttended: undefined as Date | undefined,
    isPerformingR2T4Calculator: false,
    calculationPeriodType: "",
    resultStatus: "",
    methodType: 1,
    isR2T4ApproveTabEnabled: false,
    isTerminationReversed: false,
    rightToCancelNoticeGivenToStudent: 2,
  };

  const [model, setModel] =
    React.useState<IStudentTermination>(defaultTermination);

  useEffect(() => {
    setCampusId(props.campusId);
    setEnrollmentId(props.enrollmentId);
    setEnrollment(props.enrollment);
    setEnrollments(props.enrollments);
    setStudent(props.student);
    setPeriodTypes(props.periodTypes);

    setModel({
      ...defaultTermination,
      studentEnrollmentId: props.enrollmentId,
      lastDateAttended: props.enrollment?.lastDateAttended,
      calculationPeriodType:props.enrollment?.calculationPeriodTypeId ?? "",
    });

  }, [props]);

  useEffect(() => {
    if (enrollmentId != "" && model?.dateWithdrawalDetermined != undefined) {
      terminationApi
        .GetIsAnyTitleIVAwardExists(
          enrollmentId,
          model?.dateWithdrawalDetermined
        )
        .then(
          (response: any) => {
            if (response) {
              setModel({ ...model, isPerformingR2T4Calculator: response });
            }
          },
          (exception: any) => {}
        );
    }
  }, [enrollmentId, model?.dateWithdrawalDetermined]);

  const handleSelectedStudent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    var enrollmentId = (event.target as HTMLInputElement).value;
    props.setEnrollmentId(enrollmentId);
  };

  const setDefault = () => {
  };

  useEffect(() => {
    if (enrollmentId != "") {
      getTermination(enrollmentId);
    }
  }, [enrollmentId, enrollments]);

  const handleFieldOnChange = (fieldId: string, value: any) => {
    let updatedModel = model;
    (updatedModel as any)[fieldId] = value;
    setModel({ ...updatedModel });
  };

  const onSubmit = async (nextTab: any) => {
    const validation = await data.triggerValidation();
    if (validation) {
      if (model.terminationId == EmptyGuid) {
        terminationApi.PostStudentTermination(model).then(
          (response: any) => {
            if (response) {
              setModel(response);
              props.setTerminationId(response?.terminationId);
              if(!nextTab)
              {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo:
                      "All the student termination details specified is saved",
                  };
                });
              }              
            }
          },
          (exception: any) => {}
        );
      } else {
        terminationApi.PutStudentTermination(model).then(
          (response: any) => {
            if (response) {
              setModel(response);
              props.setTerminationId(response?.terminationId);
              if(!nextTab)
              {
                props.setSnackBar((props: any) => {
                  return {
                    variant: "success",
                    showSnackBar: true,
                    messageInfo:
                      "All the student termination details specified is saved",
                  };
                });
              }  
            }
          },
          (exception: any) => {}
        );
      }
      if (nextTab) props.setTab(1);
    }
  };

  const onCancel = async (confirm?: boolean) => {
    props.setConfirmationDialog({
      open: true,
      message:
        "Are you sure you want to cancel the student termination process? All current data entered, including the previously saved data, will be lost",
      onOk: () =>
        model.terminationId != EmptyGuid ? deleteTermination() : null,
      onCancel: () => setLoading(false),
    });
  };

  const getTermination = (enrollmentId: any) => {
    terminationApi.GetStudentTermination(enrollmentId).then(
      (response: any) => {
        if (response) {
          if (response.terminationId == EmptyGuid) {
            setDefault();
          } else {
            setModel(response);
            props.setTerminationId(response?.terminationId);
          }
        }
      },
      (exception: any) => {}
    );
  };

  const deleteTermination = () => {
    if (model.terminationId != EmptyGuid)
    {
      props.deleteTermination(model.terminationId);
    }      
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1}>
        <Grid item md={7} sm={7} xs={12}>
          <b>Student: </b> ({student?.studentNumber}) {student?.studentName}
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Enrollment:</b>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    aria-label="stuEnrollId"
                    name="stuEnrollId"
                    value={model.studentEnrollmentId}
                    onChange={handleSelectedStudent}
                  >
                    {enrollments?.map((enr: IStudentEnrollments) => (
                      <FormControlLabel
                        labelPlacement="end"
                        value={enr.enrollmentId}
                        control={<Radio />}
                        label={
                          enr.programVersionDescription +
                          " - " +
                          enr.statusCodeDescription +
                          " " +
                          new Date(enr.effectiveDate).toLocaleDateString()
                        }
                      />
                    ))}
                  </RadioGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </table>
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <TerminationEnrollmentStatusAutoComplete
            filterHandle={(v: any) => {
              handleFieldOnChange("statusCodeId", v ? v.value : null);
            }}
            campusId={campusId}
            studentEnrollmentId={enrollmentId}
            params={{
              fullWidth: true,
            }}
            id="statusCodeId"
            name="statusCodeId"
            label="Status *"
            error={!!data.errors.statusCodeId}
            inputRef={data.register({ required: true })}
            helperText={
              data.errors.statusCodeId ? "Status code is required." : undefined
            }
            valueFilter={
              model?.statusCodeId
                ? {
                    key: "value",
                    values: [model?.statusCodeId],
                  }
                : undefined
            }
          />
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <DropReasonAutoComplete
            filterHandle={(v: any) => {
              handleFieldOnChange("dropReasonId", v ? v.value : null);
            }}
            statusCodeId={model?.statusCodeId}
            params={{
              fullWidth: true,
            }}
            id="dropReasonId"
            name="dropReasonId"
            label="Reason *"
            error={!!data.errors.dropReasonId}
            inputRef={data.register({ required: true })}
            helperText={
              data.errors.dropReasonId ? "Drop reason is required." : undefined
            }
            valueFilter={
              model?.dropReasonId
                ? {
                    key: "value",
                    values: [model?.dropReasonId],
                  }
                : undefined
            }
          />
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              id="dateWithdrawalDetermined"
              name="dateWithdrawalDetermined"
              label="Date of determined *"
              autoOk={true}
              value={
                model.dateWithdrawalDetermined
                  ? model.dateWithdrawalDetermined
                  : null
              }
              onChange={(e: any, value: any) =>
                handleFieldOnChange(
                  "dateWithdrawalDetermined",
                  value ? value : undefined
                )
              }
              className={classes.startDateField}
              KeyboardButtonProps={{
                "aria-label": "report date",
              }}
              error={!!data.errors["dateWithdrawalDetermined"]}
              innerRef={(ref: any) => {
                data.register("dateWithdrawalDetermined", {
                  required: true,
                });
                data.setValue(
                  "dateWithdrawalDetermined",
                  model.dateWithdrawalDetermined
                );
              }}
              helperText={
                data.errors["dateWithdrawalDetermined"]
                  ? (data.errors["dateWithdrawalDetermined"] as FieldError)
                      .message
                  : undefined
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          (Date withdrawal determined)
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disabled={true}
              variant="inline"
              format="MM/dd/yyyy"
              id="lastDateAttended"
              name="lastDateAttended"
              label="Last date attended *"
              value={model.lastDateAttended ? model.lastDateAttended : null}
              onChange={(e: any, value: any) =>
                handleFieldOnChange("lastDateAttended", value ? value : null)
              }
              className={classes.startDateField}
              KeyboardButtonProps={{
                "aria-label": "end date",
              }}
              readOnly={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={7} sm={7} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={model.isPerformingR2T4Calculator}
                onChange={(e) =>
                  handleFieldOnChange(
                    "isPerformingR2T4Calculator",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            label="Perform R2T4"
          />
        </Grid>
        {enrollment?.calculationPeriodTypeId != null &&
        model.isPerformingR2T4Calculator == true ? (
          <Grid item md={7} sm={7} xs={12}>
            <FormControl
              component="fieldset"
              className={classes.fieldSet}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormLabel
                component="legend"
                style={{ marginRight: "1rem", whiteSpace: "nowrap" }}
              >
                Period used for calculation
              </FormLabel>
              <RadioGroup
                aria-label="calculationPeriodTypeId"
                name="calculationPeriodTypeId"
                value={enrollment?.calculationPeriodTypeId ?? ""}
                //onChange={handleSelectedPeriod}
                style={{ display: "flex", flexDirection: "row" }}
              >
                {periodTypes?.map((pt: IPeriodTypes) => (
                  <FormControlLabel
                    key={pt.value}
                    disabled={true}
                    value={pt.value}
                    control={<Radio />}
                    label={pt.text}
                    labelPlacement="end"
                    style={{ marginRight: "1rem" }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        <Grid item md={7} sm={7} xs={12}>
          <FormControlLabel
            id="excludeFrom1098"
            className={classes.switch}
            labelPlacement="start"
            label="Right to cancel notice given to student"
            control={
              <Switch
                checked={
                  model.rightToCancelNoticeGivenToStudent == 1 ? true : false
                }
                name="rightToCancelNoticeGivenToStudent"
                onChange={(e: any) => {
                  handleFieldOnChange(
                    "rightToCancelNoticeGivenToStudent",
                    e ? (e.target.checked == true ? 1 : 2) : null
                  );
                }}
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>

      <Grid spacing={1}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.setTab(0)}
            style={{ marginRight: "8px" }}
          >
            &lt; Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onSubmit(true)}
            style={{ marginRight: "8px" }}
          >
            Next &gt;
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit(false)}
            style={{ marginRight: "8px" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onCancel(true)}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
        </Box>
      </Grid>
    </div>
  );
};

export default TerminationDetailTab;
