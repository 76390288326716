import { createStyles, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { IReportOverview } from "../../../../interfaces/reports/IReportOverview";
import React from "react";
import { FieldError, FormContext, useForm } from "react-hook-form";
import { IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam } from "../../../../interfaces/reports/IPEDSWinterStudentFAMilitaryBenefitsDetailSummary";
import { useSelector } from "react-redux";
import { getWinterStudentFAMilitaryDetailSummaryReport } from "../../../../api/reports/ipeds/IPEDSWinterStudentFAMilitaryBenefitsDetailSummaryApi";
import MultiCampusAutoComplete from "../../../../components/AutoComplete/MultiCampusAutoComplete";
import ProgramAutoComplete from "../../../../components/AutoComplete/ProgramAutoComplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropDownListItem } from "../../../../interfaces/DropDownListItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { cloneDeep } from "lodash";
import ReportOverview from "../../../../components/_Layout/Reports/ReportOverview";


const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(3),
            minHeight: "100%",
            height: "100%",
            maxWidth: "1050px",
            padding: theme.spacing(2),
        },
        "& .makeStyles-content": {
            backgroundColor: theme.palette.background.paper,
        },
        paperRoot: {
            padding: theme.spacing(3, 2),
            width: "100%",
            minHeight: "100%",
            height: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
        },
        searchActions: {
            marginTop: theme.spacing(3),
            textAlign: "left",
        },
        startDateField: {
            width: "100%",
        },
        cardTitle: {
            fontSize: 17,
            color: theme.palette.primary.headerText,
            textDecoration: "bold",
            backgroundColor: theme.palette.secondary.dark,
            padding: theme.spacing(1, 1),
            marginBottom: theme.spacing(1),
        },
        headerText: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardContent: {
            padding: theme.spacing(1, 3),
        },
        switch: {
            display: "flex",
            alignItems: "flex-end",
            padding: theme.spacing(0, 0.5),
            height: theme.spacing(8.6),
        },
        fieldSet: {
            marginTop: theme.spacing(2),
        },
    })
);


interface ICohortYearDataSource {
    startDate: string;
    endDate: string;
    cohortYear: number;
}


const reportTypes: Array<DropDownListItem> = [
    { text: "Academic Reporters - Public", id: "AcademicReportersPublic" },
    { text: "Academic Reporters - Private", id: "AcademicReportersPrivate" },
    { text: "Program Reporters - Public", id: "ProgramReportersPublic" },
    { text: "Program Reporters - Private", id: "ProgramReportersPrivate" },
];

const getSelectedItem = (items: Array<DropDownListItem>, value: string) =>
    items.find((opt) => opt.id === value) || ({} as DropDownListItem);

const WinterStudentFAMilitaryBenefitsDetailAndSummary = (props: any) => {

    const { report } = props;
    let reportOverview = { report: report } as IReportOverview;
    const classes = useStyles({});
    const [preview, setPreview] = React.useState<any>();
    const [previewLoader, setPreviewLoader] = React.useState<boolean>();
    const userSelectedCampus = useSelector((state: any) =>
        state.userstate.getSelectedCampus(state.session.user.userId)
    );


    const currentYear = new Date().getFullYear() - 1;

    const cohortYearOnChange = (e: ICohortYearDataSource) => {
        if (!!e && !!e.cohortYear && e.cohortYear > 0) {
            let updatedModel = { ...model };
            updatedModel.startDate =
                model.schoolReportType == "program"
                    ? e.startDate
                    : `${e.cohortYear}/10/15`;
            updatedModel.endDate =
                model.schoolReportType == "program"
                    ? e.endDate
                    : `${e.cohortYear}/10/15`;
            updatedModel.cohortYear = e.cohortYear;
            updatedModel.selectedAcademicYearDate = new Date(e.cohortYear, 15, 9);
            setModel({ ...updatedModel });
        }
    };

    const data = useForm<any>({ mode: "onBlur" });
    const initialModel: IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam = {
        campusId: userSelectedCampus,
        startDate: `${currentYear}/07/01`,
        endDate: `${currentYear}/06/30`,
        largestProgramID: [],
        cohortYear: currentYear - 1,
        orderBy: "Student Number",
        schoolReportType: "program",
        showSSN: false,
        selectedAcademicYearDate: new Date(currentYear, 15, 9),
        showInactive: false,
        institutionType: null,
    };
    const [model, setModel] =
        React.useState<IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam>(initialModel);

    const cohortYearDataSource = React.useMemo(() => {
        let toReturn: ICohortYearDataSource[] = [];
        let startYear: number = currentYear - 10;
        let endYear: number = currentYear;

        for (let i = endYear; i > startYear; i--) {
            toReturn.push({
                endDate: `06/30/${i}`,
                cohortYear: i,
                startDate: `07/01/${i}`,
            });
        }

        return toReturn;
    }, [model.selectedAcademicYearDate]);

    const cohortYearAutoComplete = {
        options: cohortYearDataSource,
        getOptionLabel: (option: ICohortYearDataSource) =>
            option.cohortYear.toString(),
    };

    const handleFieldOnChange = (
        fieldId: string,
        value: any,
        mapFunction?: Function
    ) => {
        let updatedModel = model;
        let newValue = mapFunction ? mapFunction(value) : value;
        (updatedModel as any)[fieldId] = newValue;
        if (fieldId == "campusId") {
            (updatedModel as any)["progId"] = null;
        }

        if (fieldId == "schoolReportType") {
            const selectedCohortYear = cohortYearDataSource.find(
                (m) => m.cohortYear == model.cohortYear
            );
            if (value == "program") {
                if (
                    !!selectedCohortYear &&
                    !!selectedCohortYear &&
                    !!model.cohortYear &&
                    model.cohortYear > 1
                ) {
                    updatedModel.startDate = selectedCohortYear.startDate;
                    updatedModel.endDate = selectedCohortYear.endDate;
                } else {
                    updatedModel.startDate = `${model.cohortYear ?? currentYear}/07/01`;
                    updatedModel.endDate = `${model.cohortYear ?? currentYear}/06/30`;
                }
            } else {
                updatedModel.startDate = `${model.cohortYear ?? currentYear}/10/15`;
                updatedModel.endDate = `${model.cohortYear ?? currentYear}/10/15`;
                (updatedModel["selectedAcademicYearDate"] as any) = `${model.cohortYear ?? currentYear
                    }/10/15`;
            }
        }

        if (fieldId == "selectedAcademicYearDate" && !!value) {
            updatedModel.startDate = value;
            updatedModel.endDate = value;
        }

        setModel({ ...updatedModel });
    };

    const { handleSubmit } = data;

    const onSubmit = async (isPreview: any) => {
        let params =
            (await getReportParameters()) as IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam;
        const validation = await data.triggerValidation();
        if (validation) {
            await getWinterStudentFAMilitaryDetailSummaryReport(params, isPreview);
        }
    };

    const filterHandler = (data: any) => {
        if (data) {
            setModel(data);
        } else {
            setModel(initialModel);
        }
    };

    const handleSortByChange = (value: "StudentName" | "Student Number") => {
        let copy = cloneDeep(model);
        copy.orderBy = value;
        setModel(copy);
    };


    const setPrograms = (programs: any) => {
        let newProgram: string[] = [];
        if (programs !== null) {
            if (Array.isArray(programs)) {
                newProgram = programs.map((gr: any) => gr.value);
            } else {
                newProgram.push(programs.value);
            }
        }
        return newProgram;
    };


    const getReportParameters = async () => {
        const success = await data.triggerValidation();
        if (model && success) {
            let startDate = model?.startDate ? model.startDate : null;
            let endDate = model?.endDate ? model.endDate : null;
            let params: IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam = {
                campusId: model?.campusId ?? userSelectedCampus,
                startDate: startDate,
                endDate: endDate,
                largestProgramID: model?.largestProgramID ?? [],
                progId: model.progId,
                cohortYear: model?.cohortYear,
                orderBy: model.orderBy,
                schoolReportType: model.schoolReportType,
                showSSN: model.showSSN,
                selectedAcademicYearDate: model.selectedAcademicYearDate,
                institutionType: model.institutionType,
            };
            return params;
        }
        return {} as IIPEDSWinterStudentFAMilitaryBenefitsDetailSummaryParam;
    };

    let parameters = (
        <div className={classes.root}>
            <FormContext {...data}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={12} sm={12} md={8}>
                            <MultiCampusAutoComplete
                                label="Campus *"
                                multiple={false}
                                id="campusId"
                                name="campusId"
                                valueFilter={
                                    model.campusId
                                        ? {
                                            key: "value",
                                            values: [model.campusId],
                                        }
                                        : undefined
                                }
                                filterHandle={(v: any) => {
                                    handleFieldOnChange("campusId", v ? v.value : null);
                                }}
                                error={!!data.errors.campusId}
                                inputRef={data.register({ required: true })}
                                helperText={
                                    data.errors.campusId ? "Campus is required." : undefined
                                }
                            />
                        </Grid>

                        <Grid item md={8} sm={8} xs={12}>
                            <ProgramAutoComplete
                                multiple
                                name="progId"
                                id="progId"
                                label="Program *"
                                params={{
                                    fullWidth: true,
                                }}
                                campusIds={model.campusId ? [model.campusId] : []}
                                includeAllValue={true}
                                showIncludeAllValue={true}
                                activeOnly={model.showInactive ? false : true}
                                filterHandle={(v: any) =>
                                    handleFieldOnChange(
                                        "progId",
                                        v && Array.isArray(v) && v.length > 0
                                            ? v.map((m) => m.value)
                                            : undefined,
                                        (vals: any[]) => {
                                            if (!!vals && vals.length > 0) {
                                                let lastValue = vals[vals.length - 1];

                                                if (lastValue === "All") {
                                                    return ["All"];
                                                } else {
                                                    return vals.filter((a) => a !== "All");
                                                }
                                            } else {
                                                return vals;
                                            }
                                        }
                                    )
                                }
                                valueFilter={
                                    model.progId
                                        ? {
                                            key: "value",
                                            values: model.progId,
                                        }
                                        : undefined
                                }
                                chipSize="small"
                                inputRef={data.register({
                                    validate: {
                                        atLeastOneRequired: (value) =>
                                            (model.progId && model.progId.length > 0) ||
                                            "Program is required.",
                                    },
                                })}
                                helperText={
                                    data.errors.progId
                                        ? "Program is required."
                                        : undefined
                                }
                                error={!!data.errors.progId}
                            />
                        </Grid>


                        {/* {model.schoolReportType == "program" && (
                            <Grid item xs={12} sm={12} md={8}>
                                <ProgramAutoComplete
                                    chipSize="small"
                                    multiple
                                    name="largestProgramID"
                                    valueFilter={
                                        model.largestProgramID
                                            ? {
                                                key: "value",
                                                values: model.largestProgramID,
                                            }
                                            : undefined
                                    }
                                    filterHandle={(v: any) =>
                                        handleFieldOnChange(
                                            "largestProgramID",
                                            v || [],
                                            setPrograms
                                        )
                                    }
                                    activeOnly={model.showInactive ? false : true}
                                    label="Program"
                                    campusIds={model.campusId ? [model.campusId] : []}
                                />
                            </Grid>
                        )} */}

                        <Grid item xs={12} container spacing={2}>
                            <Grid item md={6} sm={6} xs={6}>
                                <FormControl component="fieldset" className={classes.fieldSet}>
                                    <FormLabel component="legend">
                                        School Reporting Type
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="schoolReportType"
                                        name="schoolReportType"
                                        value={model.schoolReportType}
                                        onChange={(_, v) => {
                                            if (v === "program") {
                                                model.institutionType = null;
                                            }
                                            if (v === "academic") {
                                                model.institutionType = "AcademicReportersPublic";
                                            }
                                            handleFieldOnChange(
                                                "schoolReportType",
                                                //e.target.value
                                                v
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            labelPlacement="end"
                                            value="program"
                                            control={<Radio color="default" />}
                                            label="Program Reporter"
                                        />
                                        <FormControlLabel
                                            labelPlacement="end"
                                            //value="academicYear"
                                            value="academic"
                                            control={<Radio color="default" />}
                                            label="Academic Year Reporter"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {model.schoolReportType === "academic" && (
                                <Grid item md={6} sm={6} xs={6}>
                                    <FormLabel component="legend">
                                        Control of Institutions
                                    </FormLabel>

                                    <Autocomplete
                                        options={reportTypes}
                                        getOptionLabel={(option) => option.text}
                                        value={getSelectedItem(
                                            reportTypes,
                                            model.institutionType ?? ""
                                        )}
                                        onChange={(e: any, value: any) =>
                                            handleFieldOnChange(
                                                "institutionType",
                                                value ? value.id : undefined
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="institutionType"
                                                error={!!data.errors.institutionType}
                                                inputRef={data.register({ required: true })}
                                                helperText={
                                                    data.errors.institutionType
                                                        ? "Institution Type is required."
                                                        : undefined
                                                }
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {model.schoolReportType == "academic" && (
                            <Grid item md={12}>
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    IMPORTANT MESSAGE:
                                </Typography>
                                <Typography variant="body1">
                                    Use the school's "Official Report Date" or October 15th in the
                                    "Report Date" field below. The student counts on this report
                                    must tie into student counts on other IPEDS Surveys, otherwise
                                    the U.S. Department of Education will flag these reports as
                                    inconsistent and will require further explanations.
                                </Typography>
                                <Typography variant="body1" style={{ paddingTop: 10 }}>
                                    {" "}
                                    In addition, the "Report End Date" chosen for this report must
                                    be used for all other IPEDS reports that have this warning
                                    message. Please double check your "Report Date" settings
                                    before submitting the data.
                                </Typography>
                            </Grid>
                        )}

                        {model.schoolReportType == "academic" && (
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        minDate={new Date(model.cohortYear, 0, 1)}
                                        maxDate={new Date(model.cohortYear, 11, 31)}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="selectedAcademicYearDate"
                                        name="selectedAcademicYearDate"
                                        label="Report Date *"
                                        value={
                                            model.selectedAcademicYearDate
                                                ? model.selectedAcademicYearDate
                                                : null
                                        }
                                        onChange={(e: any, value: any) =>
                                            handleFieldOnChange(
                                                "selectedAcademicYearDate",
                                                value ? value : undefined
                                            )
                                        }
                                        KeyboardButtonProps={{
                                            "aria-label": "report date",
                                        }}
                                        error={!!data.errors["selectedAcademicYearDate"]}
                                        innerRef={(ref: any) => {
                                            data.register("selectedAcademicYearDate", {
                                                required: true,
                                            });
                                            data.setValue(
                                                "selectedAcademicYearDate",
                                                model.selectedAcademicYearDate
                                            );
                                        }}
                                        helperText={
                                            data.errors["selectedAcademicYearDate"]
                                                ? (
                                                    data.errors[
                                                    "selectedAcademicYearDate"
                                                    ] as FieldError
                                                ).message
                                                : undefined
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        )}

                        <Grid item xs={4}>
                            {model.schoolReportType == "program" && (
                                <Typography>
                                    {`Report Date: ${cohortYearDataSource.findIndex(
                                        (m) => m.cohortYear === model.cohortYear
                                    ) >= 0
                                        ? (() => {
                                            const currentYearData = cohortYearDataSource.find(
                                                (m) => m.cohortYear === model.cohortYear
                                            );
                                            const nextYearData = cohortYearDataSource.find(
                                                (m) => m.cohortYear === model.cohortYear + 1
                                            );

                                            if (currentYearData) {
                                                const startDate = currentYearData.startDate;
                                                const endDate =
                                                    nextYearData?.endDate ??
                                                    (() => {
                                                        if (startDate) {
                                                            const nextYearDate = new Date(startDate);
                                                            nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);
                                                            nextYearDate.setMonth(5); // June
                                                            nextYearDate.setDate(30); // 30th
                                                            return nextYearDate.toLocaleDateString();
                                                        }
                                                        return null;
                                                    })();

                                                return `${startDate} thru ${endDate}`;
                                            }

                                            return "";
                                        })()
                                        : ""
                                        }`}
                                </Typography>
                            )}
                            <Autocomplete
                                {...cohortYearAutoComplete}
                                autoComplete
                                includeInputInList
                                value={
                                    !!model.cohortYear
                                        ? cohortYearDataSource.find(
                                            (m) => m.cohortYear == model.cohortYear
                                        )
                                        : null
                                }
                                onChange={(e: any, v: any) => {
                                    cohortYearOnChange(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Cohort Year *`}
                                        name="cohortYear"
                                        error={!!data.errors.cohortYear}
                                        inputRef={data.register({ required: true })}
                                        helperText={
                                            data.errors.exportType
                                                ? "Cohort Year is required."
                                                : undefined
                                        }
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.fieldSet}>
                                <FormLabel component="legend">Sort By</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="orderBy"
                                    name="orderBy"
                                    value={model.orderBy}
                                    onChange={(e: any) => {
                                        handleSortByChange(e.target.value);
                                    }}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="Student Number"
                                        control={<Radio color="default" />}
                                        label="Student Number"
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="LastName"
                                        control={<Radio color="default" />}
                                        label="Last Name"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </form>
            </FormContext>
        </div>
    );
    reportOverview.parameters = parameters;

    return (
        <ReportOverview
            reportOverview={reportOverview}
            filterHandler={filterHandler}
            getReportParameters={getReportParameters}
            exportHandler={onSubmit}
            preview={preview}
            previewLoader={previewLoader}
            hidePreview={true}
        />
    );
};
export default WinterStudentFAMilitaryBenefitsDetailAndSummary;